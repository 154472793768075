import axios from "axios";
import Params from "../constants";


export const VARIANT_TEMPLATE = {
    "variant_name": null,
    "variant_type": null,
    "mask_id": null
}

export const VARIANT_TYPES = ["FLAVOUR", "FRAGRANCE", "COLOR", "QUALITY_GRADE", "DIFFERENTIATOR"];
const auth_headers = Params.AXIOS_AUTH_HEADERS(localStorage.getItem("x-t"));

export const fetchVariants = async () => {

    return axios.post(Params.OPS_SERVICE_BASE_URL + Params.FETCH_PRESET_VARIANTS, {}, auth_headers).then(
        (res) => res.data
    ).catch(err => console.log(err));
}