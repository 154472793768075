import { createRef, useEffect, useRef, useState } from "react";
import { PRODUCT_TEMPLATE } from "./productTemplate";
import QuantitySelector from "./QuantitySelector";
import { fetchVariants } from "./variantTemplate";
import { FREQUENCY_CATEGORIES } from "./frequencyClassTemplate";
import { FMCG_CATEGORIES } from "./fmcgCategoryTemplate";
import { fetchBrands } from "./brandTemplate";
import { addToProductService, getTextFromImage } from "./ActionService";
import imgg from "../images/texture.png";
import Cropper, { ReactCropperElement as ss } from "react-cropper";


import "cropperjs/dist/cropper";

function AddProduct(props) {


    // to be fetched
    const [VARIANTS, setPresetVariants] = useState([]);
    const [BRANDS, setPresetBrands] = useState([]);

    const [title, setTitle] = useState("");
    const [mrp, setMrp] = useState("");

    const [synonyms, setSynonyms] = useState("");
    const [product, setProduct] = useState(PRODUCT_TEMPLATE);
    const [prepareProduct, setPrepareProduct] = useState(PRODUCT_TEMPLATE);
    const [variant, setVariant] = useState("");
    const [product_retail_form, setProductRetailForm] = useState(1);
    const [barcodeFile, setBarcodeFile] = useState(undefined);
    const [barcode_number, setBarcodeNumber] = useState("");
    const [fetching_barcode, setFetchingBarcode] = useState(false);
    const [cropImage, setCropImage] = useState(false);
    const [croppedFile, setFile] = useState(null);
    const [croppedCanvasUrl, setCanvasUrl] = useState(null);
    const [imgUrl, setUrl] = useState("");

    const cancelCrop = () => {
        setCropImage(false);
    }

    var uploadBarcode = useRef(null);
    const onCrop = () => {
        // console.log(`cropperRed::`, cropperRef.current);
        var cropper = cropperRef.current.cropper;
        setCanvasUrl(cropper.getCroppedCanvas().toDataURL());
    };
    const cropperRef = createRef(null);

    // current item details
    const [currentItem, setCurrentItem] = useState({});

    // current item metrics
    const [itemMetrics, setItemMetrics] = useState({});

    const [choose_variant, setChooseVariant] = useState(false);

    var fmcgCategories = FMCG_CATEGORIES;
    const clearForm = () => {
        fmcgCategories.map(cat => {
            if (cat.id == 1) {
                cat.selected = true;
            } else {
                cat.selected = false;
            }
        })
        setTitle("");
        setMrp("");
        setSynonyms("");
        setProduct(PRODUCT_TEMPLATE);
        setPrepareProduct(PRODUCT_TEMPLATE);
        setVariant("");
        setItemMetrics({});

    }

    var frequencyCategories = FREQUENCY_CATEGORIES;
    const [SAVE_PRODUCT, setSaveProduct] = useState(false);
    var flavors = [];
    var fragrances = [];
    var qualities = [];
    var colors = [];
    var differentiators = [];
    if (VARIANTS) {
        flavors = VARIANTS.filter(v => v.variant_type == "FLAVOUR");
        fragrances = VARIANTS.filter(v => v.variant_type == "FRAGRANCE");
        qualities = VARIANTS.filter(v => v.variant_type == "QUALITY_GRADE");
        colors = VARIANTS.filter(v => v.variant_type == "COLOR");
        differentiators = VARIANTS.filter(v => v.variant_type == "DIFFERENTIATOR");

    }

    var product_retail_forms = [{ "_id": 1, "name": "Packed" }, { "_id": 2, "name": "Loose" }];

    var confirmOptions = [{ "_id": "ca_1", "name": "Yes" }, { "_id": "ca_2", "name": "No" }];
    var availabilityOptions = [{ "_id": "ca_1", "name": "Throughout" }, { "_id": "ca_2", "name": "Special Stores" }];

    const saveValues = (e) => {
        if (title.length < 4 || mrp < 1 || synonyms.length < 5) {
            props.set_toast_message("Please check the entered values");
            return;
        }
        setSaveProduct(true);
        setProduct({ ...product, max_retail_price: Number(mrp), name: title.trim(), item_metrics: itemMetrics, variant: variant.length > 0 ? (VARIANTS.filter(i => i._id == variant).at(0)._id) : null, brand: BRANDS.filter(i => i.selected).at(0)._id, fmcg_sub_category_id: Number(fmcgCategories.filter(i => i.selected).at(0).id), name_alias: synonyms, package_state: Number(getValue("sbf_1")), frequency_class: Number(getValue("sbf_2")), is_bundled: getValue("sbf_3") == "ca_1" ? true : false, availability_segment: getValue("sbf_4") == "ca_1" ? "THROUGHOUT" : "SPECIAL_STORES", metadata: JSON.stringify({ "barcode": barcode_number }) });
        setPrepareProduct({ ...product, max_retail_price: Number(mrp), name: title.trim(), item_metrics: itemMetrics, variant: variant.length > 0 ? (VARIANTS.filter(i => i._id == variant).at(0)._id) : null, brand: BRANDS.filter(i => i.selected).at(0)._id, fmcg_sub_category_id: Number(fmcgCategories.filter(i => i.selected).at(0).id), name_alias: synonyms, package_state: Number(getValue("sbf_1")), frequency_class: Number(getValue("sbf_2")), is_bundled: getValue("sbf_3") == "ca_1" ? true : false, availability_segment: getValue("sbf_4") == "ca_1" ? "THROUGHOUT" : "SPECIAL_STORES", metadata: JSON.stringify({ "barcode": barcode_number }) });

    }
    const setFMCGCategory = (e) => {
        props.set_toast_message("FMCG category updated");
        let selected = e.target.value;


        fmcgCategories.map(cat => {
            if (cat.id == selected) {
                cat.selected = true;
            } else {
                cat.selected = false;
            }
        })

        // console.log(fmcgCategories);
    }

    const setBrandName = (e) => {
        let selected = e.target.value;
        BRANDS.map(b => {
            if (b._id == selected) {
                b.selected = true;
            } else {
                b.selected = false;
            }
        })
    }

    const setMaxRetailPrice = (val) => {

        if (val == "" || (Number(val) <= 10000 && Number(val) > 0)) setMrp(val);

    }

    const chooseVariant = (e) => {
        e.preventDefault();
        setChooseVariant(true);
        if (VARIANTS) setVariant(VARIANTS.at(0)._id);
    }

    const selectOption = (e) => {
        e.preventDefault();
        let p = Array.prototype.slice.call(e.target.parentNode.attributes).find(x => x.localName == "data-select-button");
        let b = Array.prototype.slice.call(e.target.attributes).find(x => x.localName == "data-select-button-val");
        let t = document.querySelector(`[data-select-button=${p.value}]`);
        t.setAttribute("data-selected-val", b.value);
        Array.prototype.slice.call(e.target.parentNode.children).map(c => { c.classList.remove("filled-dark") });
        e.target.classList.add("filled-dark");
    }

    const getValue = (select_field_name) => {
        let t = document.querySelector(`[data-select-button=${select_field_name}]`);
        return t.getAttribute("data-selected-val");
    }

    var item_metrics = {
        // "item_id": product._id,
        "quantity": 1,
        "weight": 0,
        "unit": 1
    };
    var item = {
        // "item_id": product._id,
        "quantity": 1,
        "weight": 0,
        "unit": 1
    };

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    useEffect(() => {
        // Set Processing state
        props.s_p(false);

        // props.show_toast(true);
        console.log("updated:", product);
        async function fetch_presets() {
            setPresetVariants(await fetchVariants());
            const temp = await fetchBrands();
            temp.sort((a, b) => { return a.name.localeCompare(b.name) });
            console.log("temp::", temp);
            setPresetBrands(temp);
        }
        fetch_presets();
        setItemMetrics(product.item_metrics);
    }, [product]);

    useEffect(() => {

        if (SAVE_PRODUCT) {
            props.s_p(true);
            addToProductService(product).then(data => {
                console.log(data);
                setSaveProduct(false);
                props.s_p(false);
                switch (data.message) {
                    case "PRODUCT_ADDED":
                        clearForm();
                        props.set_toast_message("Product added successfully")
                        break;
                    case "PRODUCT_NOT_ADDED":
                        props.set_toast_message("Product not added");
                        break;
                    default:
                        props.set_toast_message("Something went wrong");

                }
            }).catch(err => {
                setSaveProduct(false);
                props.s_p(false);
                switch (err.response.data.message) {
                    case "VALIDATION_FAILED":
                        props.set_toast_message("Please fill in all the fields");
                        break;

                }

            });
        }
        // }ss
    }, [prepareProduct]);

    let getBarcodeFromImage = async () => {
        setCropImage(false);
        var fd = new FormData();
        fd.append("file", dataURLtoFile(croppedCanvasUrl, barcodeFile.name));
        // fd.append("base64", imgUrl);
        setFetchingBarcode(true);

        await getTextFromImage(fd).then(data => {
            setFetchingBarcode(false);
            switch (data.code) {
                case "TEXT_EXTRACTED":
                    let barcode = data.data.parsed.at(0);
                    barcode = barcode.replace("\r", "").replace("\n", "").replace("\t", "").replace("\\r", "").replace("\\t", "");
                    console.log("barcode::", barcode)
                    setBarcodeNumber(data.data.parsed.at(0));
                    break;
                default:
                    props.set_toast_message("An error occurred");

            }
        })
    }

    // useEffect(() => {
    //     if (imgUrl) {
    //         let file = dataURLtoFile(imgUrl, "newFile_.png");
    //         setFile(file);
    //     }

    // }, [croppedCanvasUrl]);

    useEffect(()=>{
        if(croppedFile){
            console.log("FILE:::", croppedFile);
        }
    }, [croppedFile]);

    useEffect(() => {
        if (!barcodeFile) {
            return;
        }
        setCropImage(true);
        const fileReader = new FileReader();
        // fileReader.readAsArrayBuffer(barcodeFile)
        fileReader.readAsDataURL(barcodeFile);
        fileReader.addEventListener("load", (e) => {
            // console.log("reaulatst::", e.target.result);   
            setUrl(e.target.result);

        });




    }, [barcodeFile]);

    return (
        <>
            <div className="card-container left-aligned margin15 rounded">
                <h3 style={{ fontSize: "1.2em" }}>Add Product</h3>
                <p className="md-font">List of merchants recently added and not yet approved</p>
            </div>
            {cropImage && <div className="cropper_popup">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h4 style={{ padding: "10px" }}>Crop Image</h4>
                    <div>
                        <button onClick={() => getBarcodeFromImage()} className="btn btn-dark sm-font">Confirm</button>
                        <button onClick={() => cancelCrop()} className="btn btn-dark sm-font">Cancel</button>
                    </div>
                </div>
                <Cropper
                    src={imgUrl}
                    crossOrigin="anonymous"
                    style={{ maxHeight: "400px", height: "fit-content", width: "100%" }}
                    // Cropper.js options
                    initialAspectRatio={6 / 1}
                    guides={true}
                    crop={onCrop}
                    ref={cropperRef}
                /></div>}
            <form className="card-container card-form">

                <div className="field">
                    <label>Product title</label>
                    <p>The product title is the name of the product</p>
                    <input value={title} onChange={(e) => { setTitle(e.target.value); }} type="text" placeholder="Product title" />
                </div>
                <div className="field">
                    <label>Product name aliases</label>
                    <p>Alternative names/synonyms for the product</p>
                    <textarea value={synonyms} onChange={(e) => { setSynonyms(e.target.value); }} type="text" placeholder="Product name aliases" />
                </div>
                <div className="field">
                    <label>Product Retail Form</label>
                    <p>Is the product sold in package or loose</p>
                    <div data-selected-val={product_retail_forms.at(0)._id} data-select-button="sbf_1" className="select_button_field">
                        {product_retail_forms.map(prf => {
                            return <button data-select-button-val={prf._id} onClick={(e) => { selectOption(e) }} className={`btn btn-dark ${prf._id == "1" ? "filled-dark" : ""} select-button`}>{prf.name}</button>
                        })}
                    </div>
                </div>
                <div className="field">
                    <label>Is this a bundle?</label>
                    <p>Select Yes if the quantity is greater than 1</p>
                    <div data-selected-val="ca_2" data-select-button="sbf_3" className="select_button_field">
                        {confirmOptions.map(c => {
                            return <button data-select-button-val={c._id} onClick={(e) => { selectOption(e) }} className={`btn btn-dark ${c._id == "ca_2" ? "filled-dark" : ""} select-button`}>{c.name}</button>
                        })}
                    </div>
                </div>
                <div className="field">
                    <label>Availability Segment</label>
                    <p>Availability of this product</p>
                    <div data-selected-val="ca_2" data-select-button="sbf_4" className="select_button_field">
                        {availabilityOptions.map(c => {
                            return <button data-select-button-val={c._id} onClick={(e) => { selectOption(e) }} className={`btn btn-dark ${c._id == "ca_1" ? "filled-dark" : ""} select-button`}>{c.name}</button>
                        })}
                    </div>
                </div>
                <div className="field">
                    <label>FMCG category</label>
                    <p>FMCG category of the product</p>
                    <select onChange={(e) => setFMCGCategory(e)} placeholder="Choose a category">
                        {
                            fmcgCategories.map(cat => {
                                return <option value={cat.id} defaultValue={cat.selected}>{cat.name}</option>
                            })
                        }
                    </select>
                </div>
                <div className="field">
                    <label>Frequency class</label>
                    <p>Frequency class of the product</p>
                    <div data-selected-val="1" data-select-button="sbf_2" className="select_button_field">
                        {frequencyCategories.map(cat => {
                            return <button data-select-button-val={cat._id} onClick={(e) => { selectOption(e) }} className={`btn btn-dark ${cat._id == "1" ? "filled-dark" : ""} select-button`}>{cat.name}</button>
                        })}
                    </div>

                </div>
                <div className="field">
                    <label>Is this a variant?</label>
                    <p>Select a variant if applicable</p>

                    {choose_variant && <select onChange={(e) => { setVariant(e.target.value) }} placeholder="Choose a category">
                        <option disabled>Flavor</option>
                        {
                            flavors.map(f => <option value={f._id}> {f.variant_name} </option>)
                        }
                        <option disabled>Fragrance</option>
                        {
                            fragrances.map(f => <option value={f._id}> {f.variant_name} </option>)
                        }
                        <option disabled>Colors</option>
                        {
                            colors.map(f => <option value={f._id}> {f.variant_name} </option>)
                        }
                        <option disabled>Quality</option>
                        {
                            qualities.map(f => <option value={f._id}> {f.variant_name} </option>)
                        }
                        <option disabled>Differentiator</option>
                        {
                            differentiators.map(f => <option value={f._id}> {f.variant_name} </option>)
                        }

                    </select>}
                    {!choose_variant && <button onClick={(e) => { chooseVariant(e) }} className="btn btn-dark">Choose a variant</button>}
                    {choose_variant && <button onClick={(e) => { setChooseVariant(false); setVariant(null); }} className="btn btn-dark">Cancel</button>}
                </div>

                <div className="field">
                    <label>Brand</label>
                    <p>Brand name of the product</p>
                    {BRANDS && <select onChange={(e) => setBrandName(e)} placeholder="Select a brand">
                        {
                            BRANDS.map(brand => {
                                return <option value={brand._id} defaultValue={brand.selected}>{brand.name}</option>
                            })
                        }
                    </select>}
                </div>
                <div className="field">
                    <label>Quantity</label>
                    <p>Leave quantity as 1 unless required</p><br />
                    <QuantitySelector setItemMetrics={setItemMetrics} itemMetrics={itemMetrics} item={item} />
                </div>
                <div className="field">
                    <label>MRP</label>
                    <p>The product title is the name of the product</p>
                    <div style={{ display: "flex", alignItems: "center" }}><span style={{ fontSize: "18px" }}>&#8377;</span> <input style={{ fontWeight: "bolder" }} value={mrp} onChange={(e) => { setMaxRetailPrice(Number(e.target.value)); }} type="number" placeholder="Maximum retail price" /></div>
                </div>
                <div className="field">
                    <label>Barcode</label>
                    <p>Upload the photo of product barcode to feed it</p>
                    {!fetching_barcode && <button className="btn btn-dark md-font" onClick={() => uploadBarcode.click()}>
                        <i className="fas fa-upload"></i>
                        Upload barcode
                    </button>}
                    {fetching_barcode && <button disabled className="btn btn-dark md-font" >
                        <i className="fas fa-upload"></i>
                        Fetching barcode..
                    </button>}
                    <input hidden ref={node => uploadBarcode = node} type="file" accept="image/*" onChange={e => setBarcodeFile(e.target.files[0])} />
                    <div style={{ display: "flex", alignItems: "center" }}><i className="fas fa-barcode"> </i><input style={{ fontWeight: "bolder" }} value={barcode_number} onChange={(e) => { setBarcodeNumber(e.target.value); }} type="text" placeholder="Barcode number" /></div>
                </div>
                {/* <div className="field">
                    <label>Product thumbnail</label>
                    <input type="file" accept="image/png" />
                </div> */}
                {!SAVE_PRODUCT && <button className="btn-dark" onClick={(e) => { e.preventDefault(); saveValues() }} >Add Product</button>}
                {SAVE_PRODUCT && <button className="btn-dark disabled-state" onClick={(e) => { e.preventDefault(); }} >Adding..</button>}
            </form>
        </>
    )
}


export default AddProduct;