import axios from "axios";
import Params from "../constants";


export const MERCHANT_TEMPLATE = {
    "mask_id": "",
    "owner_first_name": "",
    "owner_last_name": "",
    "store_name": "",
    "store_legal_name": "",
    "store_name": "",
    "store_age": 1,
    "store_area": {},
    "gst_details": {},
    "metadata": {},
    "activity_status": "",
    "id_proof_src_url": "",
    "address_proof_src_url": ""
}

export const ACTIVITY_STATUS = {
    "IN_REVIEW": "In Review",
    "ACTIVE": "Active"

}

const auth_headers = Params.AXIOS_AUTH_HEADERS(localStorage.getItem("x-t"));

export const fetchDraftMerchants = async () => {

    return axios.post(Params.OPS_SERVICE_BASE_URL + Params.FETCH_DRAFT_MERCHANTS, {}, auth_headers).then(
        (res) => res.data
    );
}

export const getMerchant = async (mask_id) => {

    return axios.post(Params.OPS_SERVICE_BASE_URL + Params.GET_MERCHANT, { mask_id: mask_id }, auth_headers).then(
        (res) => res.data
    );
}
