import { useDebugValue, useEffect, useRef, useState } from "react";
import { convertISOStringToMonthDay, generateFileId } from "../utils";
import { updateProduct, uploadFile } from "./ActionService";
import { FREQUENCY_CATEGORIES } from "./frequencyClassTemplate";
import Params from "../constants";



function ProductDetailCard(props) {


    const [editMode, setEditMode] = useState(false);
    const [newMrp, setMrp] = useState(props.selectedProduct.max_retail_price);
    const [newAliases, setAliases] = useState(props.selectedProduct.name_alias);
    const [newAvailabilitySegment, setAvailabilitySegment] = useState(props.selectedProduct.availability_segment);
    const [is_updating, setUpdating] = useState(false);
    const [addThumbnail, setAddThumbnail] = useState(false);
    const [uploadThumbnail, setUploadThumbnail] = useState(false);
    const [thumbnailFile, setThumbnailFile] = useState(undefined);
    const [thumbnailUrl, setThumbnailUrl] = useState("");

    var product = props.selectedProduct;
    var availabilityOptions = [{ "_id": "ca_1", "name": "THROUGHOUT" }, { "_id": "ca_2", "name": "SPECIAL_STORES" }];
    var availability_options = {
        "THROUGHOUT": "Throughout",
        "SPECIAL_STORES": "Special Stores"
    }
    var uploadForm = useRef(null);


    useEffect(() => {
        if (addThumbnail) {

            if (uploadForm != undefined && uploadForm != null) { uploadForm.click(); }
        }else{
            setThumbnailFile(undefined);
            // setThumbnailUrl("");
        }
    }, [addThumbnail]);

    useEffect(() => {
        console.log("thumbnail url::", thumbnailUrl);
    }, [thumbnailUrl]);

    useEffect(() => {
        if (thumbnailFile) {
            if (uploadForm.files[0].name && uploadThumbnail) {
                const file_id = generateFileId("product_thumbnail");

                let upload_thumbnail = async () => {

                    var fd = new FormData();
                    fd.append("file", thumbnailFile);
                    fd.append("file_name", file_id);

                    await uploadFile(fd).then(data => {
                        switch (data.code) {
                            case "FILE_UPLOADED":
                                setThumbnailUrl(Params.S3_BUCKET_URL + file_id + ".png");
                                setUploadThumbnail(false);
                                setAddThumbnail(false);
                                setThumbnailFile(undefined);
                                props.set_toast_message("File uploaded");
                                break;
                            default:
                                props.set_toast_message("An error occurred");

                        }
                    })
                }

                upload_thumbnail();

            } else {
                return;
            }
        }
    }, [uploadThumbnail]);


    const removeProduct = async (product_name, brand_mask_id, variant_mask_id, mrp) => {
        variant_mask_id = variant_mask_id ? variant_mask_id.mask_id : null;
        brand_mask_id = brand_mask_id ? brand_mask_id.mask_id : null;
        alert(`You are about to delete ${product_name}.`);
        console.log("temp:", props.catalogue);
        if (window.confirm(`Are you sure you want to delete this product?`)) {
            props.s_p(true);
            await updateProduct({ "name": product_name, "brand_mask_id": brand_mask_id, "variant_mask_id": variant_mask_id, "max_retail_price": mrp, "metadata": -1 }).then(data => {
                props.s_s(false);
                props.s_p(false);
                if (data.modifiedCount) {
                    props.set_results_count(props.results_count - data.modifiedCount)
                }

                props.set_catalogue(props.catalogue.filter(prod => prod.name != product_name));
            })
        };

    }

    const submitEdits = async (product_name, brand_mask_id, variant_mask_id) => {
        variant_mask_id = variant_mask_id ? variant_mask_id.mask_id : null;
        brand_mask_id = brand_mask_id ? brand_mask_id.mask_id : null;
        console.log(newAliases, newMrp);
        if (newAliases.length > 4 && (Number(newMrp) <= 10000 && Number(newMrp) > 0)) {
            props.s_p(true);
            setUpdating(true);
            await updateProduct({ "name": product_name, "brand_mask_id": brand_mask_id, "variant_mask_id": variant_mask_id, thumbnail_url: thumbnailUrl, name_alias: newAliases, max_retail_price: newMrp, availability_segment: getValue("sbf_5") == "ca_1" ? "THROUGHOUT" : "SPECIAL_STORES" }).then(data => {
                setEditMode(false);
                setUpdating(false);
                setThumbnailFile(undefined);
                setAddThumbnail(false);
                setThumbnailUrl("");
                product.thumbnail_url = thumbnailUrl;
                product.max_retail_price = newMrp;
                product.name_alias = newAliases;
                product.availability_segment = getValue("sbf_5") == "ca_1" ? "THROUGHOUT" : "SPECIAL_STORES";
                props.s_p(false);


            })
        }
    }
    const getValue = (select_field_name) => {
        let t = document.querySelector(`[data-select-button=${select_field_name}]`);
        return t.getAttribute("data-selected-val");
    }
    const selectOption = (e) => {
        e.preventDefault();
        let p = Array.prototype.slice.call(e.target.parentNode.attributes).find(x => x.localName == "data-select-button");
        let b = Array.prototype.slice.call(e.target.attributes).find(x => x.localName == "data-select-button-val");
        let t = document.querySelector(`[data-select-button=${p.value}]`);
        t.setAttribute("data-selected-val", b.value);
        Array.prototype.slice.call(e.target.parentNode.children).map(c => { c.classList.remove("filled-dark") });
        e.target.classList.add("filled-dark");

    }

    const units = {
        "1": "g",
        "2": "Kg",
        "3": "ml",
        "4": "L"
    }

    return (
        <div className="card-container floating-card">
            <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between", paddingBottom: "10px" }}>
                <h3 style={{ fontSize: "1.3rem" }}>{product.name}</h3>
                <div>
                    {!editMode && <i onClick={(e) => { e.preventDefault(); setEditMode(true); }} style={{ marginRight: "20px" }} className="fas fa-edit"></i>}
                    <i onClick={() => { props.s_s(false); setEditMode(false); }} style={{ marginRight: "10px" }} className="fas fa-times"></i>
                </div>
            </div>
            {/* <br /> */}
            <hr />
            <div style={{ width: "80%", display: "flex", flexDirection: "column", alignItems: "space-evenly", paddingTop: "10px" }}>
                <div>

                    {product.brand && <span style={{ display: "flex", justifyContent: "" }}><h4 style={{ marginRight: "2.5%" }} >Brand: </h4> {product.brand.name}</span>}
                    <span style={{ display: "flex", justifyContent: "", alignItems: "center" }}><h4 style={{ marginRight: "2.5%" }}>Added on: </h4> {convertISOStringToMonthDay(product.createdAt)}</span>
                    <span style={{ display: "flex", justifyContent: "", alignItems: "center" }}><h4 style={{ marginRight: "2.5%" }}>Weight: </h4> {product.item_metrics.weight}{units[product.item_metrics.unit]}</span>
                    <span style={{ display: "flex", justifyContent: "", alignItems: "center" }}><h4 style={{ marginRight: "2.5%" }}>Frequency: </h4> {FREQUENCY_CATEGORIES.find(i => i._id == product.frequency_class).name} </span>
                    {!editMode && <span style={{ display: "flex", justifyContent: "", alignItems: "center" }}><h4 style={{ marginRight: "2.5%" }}>Availability: </h4> <span style={product.availability_segment == "THROUGHOUT" ? { color: "green", fontWeight: "bolder" } : { color: "#6d00ff", fontWeight: "bolder" }}>{product.availability_segment == 'THROUGHOUT' ? 'Throughout' : 'Special Stores'}</span> </span>}
                    {editMode && <><span style={{ display: "flex", justifyContent: "", alignItems: "center" }}><h4 style={{ marginRight: "2.5%" }}>Availability: </h4></span>
                        <div data-selected-val={product.availability_segment == "THROUGHOUT" ? "ca_1" : "ca_2"} data-select-button="sbf_5" className="select_button_field" style={{ flexWrap: "nowrap" }}>
                            {availabilityOptions.map(c => {
                                return <button data-select-button-val={c._id} onClick={(e) => { selectOption(e) }} className={`btn btn-dark sm-font ${c._id == availabilityOptions.find(val => val.name == product.availability_segment)._id ? "filled-dark" : ""} select-button`}>{availability_options[c.name]}</button>
                            })}
                        </div></>
                    }

                    {!editMode && <span style={{ display: "flex", justifyContent: "", alignItems: "center" }}><h4 style={{ marginRight: "2.5%" }}>Aliases: </h4> <p style={{ fontSize: "0.85rem" }}>{product.name_alias} </p></span>}
                    {editMode && <span style={{ display: "flex", justifyContent: "", alignItems: "center" }}><h4 style={{ marginRight: "2.5%" }}>Aliases: </h4> <input onChange={(e) => { setAliases(e.target.value) }} className="editField" value={newAliases} /> </span>}
                    {product.variant && <span style={{ display: "flex", justifyContent: "", alignItems: "center" }}><h4 style={{ marginRight: "2.5%" }}>Variant: </h4> <p style={{ fontSize: "0.85rem" }}>{product.variant.variant_name} | {product.variant.variant_type}</p> </span>}
                    <br />
                    {!editMode && <div className="mrp" style={{ display: "flex", justifyContent: "flex-start", fontSize: "1.45em", padding: "4px 15px" }}>
                        <span>&#8377;</span>&nbsp;{product.max_retail_price}

                    </div>}
                    {editMode && <div className="mrp" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", fontSize: "1.5em", width: "fit-content", color:"#0e883e", background:"transparent" }}>
                        <span style={{ fontSize: "16px" }}>&#8377;</span><input type="number" onChange={(e) => { setMrp(e.target.value) }} className="editField" value={newMrp} style={{ width: "100%", fontWeight:"bold" }} />
                    </div>}
                    <br /><br />
                    {editMode && !is_updating && !addThumbnail && <button style={{ marginLeft: "0px" }} class="btn btn-dark sm-font margin15" onClick={(e) => { e.preventDefault(); setAddThumbnail(true); }}><i style={{ padding: "0px" }} className="fas fa-camera"></i> &nbsp; Add thumbnail</button>}
                    {editMode && !is_updating && addThumbnail && <button style={{ marginLeft: "0px" }} class="btn btn-dark sm-font margin15" onClick={(e) => { e.preventDefault(); setAddThumbnail(false); }}><i style={{ padding: "0px" }} className="fas fa-trash"></i> &nbsp; Remove</button>}
                    {editMode && addThumbnail && !is_updating && <form>
                        <input ref={node => uploadForm = node} onChange={(e) => setThumbnailFile(e.target.files[0])} type="file" accept="image/*" />
                    </form>}
                    {editMode && addThumbnail && !is_updating && <button style={{ marginLeft: "0px" }} class="btn btn-dark sm-font margin15" onClick={(e) => { e.preventDefault(); setUploadThumbnail(true) }}>Upload thumbnail</button>}
                    {/* update product button */}
                    {editMode && !addThumbnail && !is_updating && <button style={{ marginLeft: "0px" }} class="btn btn-dark sm-font margin15" onClick={(e) => { e.preventDefault(); submitEdits(product.name, product.brand, product.variant) }}>Update product</button>}
                    {/* Cancel button */}
                    {editMode && !is_updating && <button style={{ marginLeft: "0px" }} class="btn btn-dark sm-font margin15" onClick={(e) => { e.preventDefault(); setEditMode(false); setAddThumbnail(false); }}>Cancel</button>}
                    {/* progress  */}
                    {editMode && is_updating && <button style={{ marginLeft: "0px" }} class="btn btn-dark disabled-state sm-font margin15" onClick={(e) => { e.preventDefault(); }}>Updating..</button>}
                    <button style={{ marginLeft: "0px" }} class="btn btn-dark danger sm-font margin15" onClick={(e) => { e.preventDefault(); removeProduct(product.name, product.brand, product.variant, product.max_retail_price) }}>Remove product</button>

                </div>
            </div>
            <br />

        </div>)
}



export default ProductDetailCard;