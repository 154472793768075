import { useEffect, useState } from "react";
import { FREQUENCY_CATEGORIES } from "./frequencyClassTemplate";
import { FMCG_CATEGORIES, FMCG_SUB_CATEGORIES } from "./fmcgCategoryTemplate";
import { VARIANT_TEMPLATE, VARIANT_TYPES } from "./variantTemplate";
import { addVariantService } from "./ActionService";
import { PRICE_BRACKETS } from "./priceBracketTemplate";

function AddVariant(props) {


    const [title, setTitle] = useState("");
    const [Variant, setVariant] = useState(VARIANT_TEMPLATE);
    const [prepareVariant, setPrepareVariant] = useState(VARIANT_TEMPLATE);
    const [SAVE_VARIANT, setSaveVariant] = useState(false);

    const [variant_type, setVariantType] = useState("FLAVOUR");

    const clearForm = () => {
        setTitle("");
        setVariant(VARIANT_TEMPLATE);
        setPrepareVariant(VARIANT_TEMPLATE);
    }

    const saveValues = (e) => {
        // console.log(fmcg_Categories, fmcgSubCategories);
        if (title.length < 3) {
            props.set_toast_message("Please check the entered values");
            return;
        }
        setVariant({...Variant, variant_name:title, variant_type:variant_type});
        setPrepareVariant({...Variant, variant_name:title, variant_type:variant_type});
        
        setSaveVariant(true);

    }


    useEffect(() => {
        // Set Processing state
        props.s_p(false);

        // props.show_toast(true);
        console.log("updated:", Variant);

    }, [Variant]);

    useEffect(() => {
        if (SAVE_VARIANT) {
            props.s_p(true);
            setSaveVariant(false);
            addVariantService(Variant).then(data => {
                console.log(data);
                props.s_p(false);
                if (data === true) {
                    props.set_toast_message("Variant added successfully");
                    clearForm();
                } else {
                    if (data.data.message == "VALIDATION_FAILED") {
                        props.set_toast_message("Validation failed");
                    }
                    if (data.status == 403) {
                        localStorage.removeItem("x-t");
                        window.location.href="/";
                        props.set_toast_message("Forbidden action");
                    }
                }

            }).catch(err => {

                console.log("api err:", err);
                switch (err.data.statusCode) {
                    case "VALIDATION_FAILED":
                        props.set_toast_message("Please fill in all the fields");
                        break;
                    case 403:
                        localStorage.removeItem("x-t");
                        props.set_toast_message("Forbidden action");
                        window.location.href="/";
                    default:
                        props.set_toast_message("Variant not added");
                }
            })
        }

        // }ss
    }, [prepareVariant]);

    return (
        <>
            <div className="card-container left-aligned margin15 rounded">
                <h3 style={{ fontSize: "1.2em" }}>Add Variant</h3>
                <p className="md-font">List of merchants recently added and not yet approved</p>
            </div>
            <form className="card-container card-form">

                <div className="field">
                    <label>Variant name</label>
                    <p>Popular name for the variant</p>
                    <input value={title} onChange={(e) => { setTitle(e.target.value); }} type="text" placeholder="Variant name" />
                </div>
              
                <div className="field">
                    <label>Variant Type</label>
                    <p>Type of the variant</p>
                    <select value={variant_type} onChange={(e) => setVariantType(e.target.value)} placeholder="Choose a variant type">
                        {
                            VARIANT_TYPES.map(vt => {
                                return <option value={vt} >{vt}</option>
                            })
                        }
                    </select>
                </div>
             


                <button className="btn-dark" onClick={(e) => { e.preventDefault(); saveValues() }} >Add Variant</button>
            </form>
        </>
    )
}


export default AddVariant;