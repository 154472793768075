import { Link } from "react-router-dom";

export default function IntroScreen() {

    return (<>
        <br />
        <div className="card-container left-aligned margin15 rounded">
            <h3>Welcome to KD-Ops</h3><br />
            <p>
                Start by selecting an action from the given list.
            </p>
        </div>
        <div className="actions-list">
            <h3 style={{ fontSize: "1.1em" }}>Products</h3><br />

            <Link className="no-decor-link" to="/view/catalogue">
                <div className="card-container row clickable"><i className="action-icon fas fa-list"></i> View Catalog</div>
            </Link>
            <Link className="no-decor-link" to="/add-a-product">
                <div className="card-container row clickable"><i className="action-icon fas fa-plus"></i> Add a Product</div>
            </Link>
            <Link className="no-decor-link" to="/add-a-variant">
                <div className="card-container row clickable"><i className="action-icon fas fa-plus"></i> Add a Variant</div>
            </Link>
            <Link className="no-decor-link" to="/add-a-brand">
                <div className="card-container row clickable"><i className="action-icon fas fa-plus"></i> Add a Brand</div>
            </Link>
            <br/>
            <h3 style={{ fontSize: "1.1em" }}>Merchant</h3><br />
            <Link className="no-decor-link" to="/merchant/onboard">
                <div className="card-container row clickable"><i className="action-icon fas fa-user"></i> Onboard Merchant</div>
            </Link>
            <Link className="no-decor-link" to="/merchant/in-review/list">
                <div className="card-container row clickable"><i className="action-icon fas fa-pen"></i> Review Merchants</div>
            </Link>
            <br/>
            <h3 style={{ fontSize: "1.1em" }}>Store</h3><br/>
            <Link className="no-decor-link" to="/store/create">
                <div className="card-container row clickable"><i className="action-icon fas fa-store"></i>Create Store</div>
            </Link>
            {/* <Link className="no-decor-link" to="#">
                <div className="card-container row clickable"><i className="action-icon fas fa-remove"></i> Remove a Product</div>
            </Link>
            <Link className="no-decor-link" to="#">
                <div className="card-container row clickable"><i className="action-icon fas fa-user"></i> Add a User</div>
            </Link> */}
        </div>
        <div className="card-container left-aligned margin15 rounded">
            <h3 style={{ fontSize: "1.1em" }}>Instructions</h3><br />

            <ul>
                <li className="flex-list-item">
                    <span>1.</span>
                    <p>

                        Add Products which are commonly available in the local market.
                    </p>
                </li>
                <li>
                    2. Avoid expensive brands.
                </li>
                <li className="flex-list-item">
                    <span>3.</span>
                    <p>Do not repeat variant or brand names in the product title.</p>
                </li>
                <li className="flex-list-item">
                    <span>4.</span>
                    <p>Variants include- Color, Fragrance, Flavour and Differentiator, and Quality Grade.</p>
                </li>
            </ul>

        </div><br />
    </>)
}