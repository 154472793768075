import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getMerchant } from "../../merchantTemplate";
import { markMerchantActive, markMerchantInActive } from "../../ActionService";



function MerchantDetailCard(props) {


    const { mask_id } = useParams();

    const [merchant, setMerchant] = useState({});

    useEffect(() => {
        props.s_p(true);
        let get_merchant = async () => {
            try {
                await getMerchant(mask_id).then(merchant_fetched => {
                    merchant_fetched && setMerchant(merchant_fetched.data);
                    merchant_fetched && merchant_fetched.data && merchant_fetched.data.mask_id != undefined && props.s_p(false);
                    !merchant_fetched && get_merchant();
                }
                ).catch(err => {
                    console.log("MFFFFF");
                    props.s_p(false);
                });
                // console.log("mf:", merchant_fetched);

            } catch (err) {
                if (err.response && err.response.data.statusCode == 403) {
                    props.set_toast_message("Forbidden");
                }
                props.s_p(false);
            }
        }
        get_merchant();
    }, []);

    const mark_merchant_active = async () => {
        await markMerchantActive({ "mask_id": mask_id }).then(data => {
            console.log(data);
            if (data && data.code == "MERCHANT_STATUS_CHANGED") {
                props.set_toast_message("Merchant approved");
                setTimeout(() => window.location.href = "/", 1500);
                return;
            }
        }
        ).catch(err => {
            props.set_toast_message("An error occurred");
        })
    }

    const mark_merchant_inactive = async () => {
        await markMerchantInActive({ "mask_id": mask_id }).then(data => {
            console.log(data);
            if (data && data.code == "MERCHANT_STATUS_CHANGED") {
                props.set_toast_message("Merchant rejected");
                setTimeout(() => window.location.href = "/", 1500);
                return;
            }
        }
        ).catch(err => {
            props.set_toast_message("An error occurred");
        })
    }

    return (<>

        <div className="card-container">
            <h3>Review</h3>
            <p className="md-font">Please go through the merchant details carefully before approving.</p>
            <br />
            <hr />
            <br />
            <div className="field margin-top_sm">
                <h5 className="light-font">Merchant first name</h5>
                <span>{merchant.owner_first_name}</span>
            </div>
            <div className="field margin-top_sm">
                <h5 className="light-font">Merchant last name</h5>
                <span>{merchant.owner_last_name}</span>
            </div>
            <div className="field margin-top_sm">
                <h5 className="light-font">Name of the Store owned</h5>
                <span>{merchant.store_name}</span>
            </div>
            <div className="field margin-top_sm">
                <h5 className="light-font">Registered name of the Store owned</h5>
                <span>{merchant.store_legal_name}</span>
            </div>
            <div className="field margin-top_sm">
                <h5 className="light-font">Years of operation</h5>
                <span>{merchant.store_age}</span>
            </div>
            <div className="field margin-top_sm">
                <h5 className="light-font">Area of Store owned</h5>
                <span>{merchant.store_area && merchant.store_area.value} {merchant.store_area && merchant.store_area.unit == 1 ? "sq.feet" : "gaj"}</span>
            </div>
            <div className="field margin-top_sm">
                <h5 className="light-font">GST Number</h5>
                <span>{merchant.gst_details && merchant.gst_details.gstin}</span>
            </div>
            <div className="field margin-top_sm">
                <h5 className="light-font">Identity Proof</h5>
                <span>{merchant.id_proof_src_url && <img className="card-img" src={merchant.id_proof_src_url} />}</span>
            </div>
            <div className="field margin-top_sm">
                <h5 className="light-font">Address Proof</h5>
                <span>{merchant.address_proof_src_url && <img className="card-img" src={merchant.address_proof_src_url} />}</span>
            </div>
            <br /><br />
            <button onClick={() => { mark_merchant_active(); }} className="btn-dark">
                Approve Merchant
            </button>
            <button onClick={() => { mark_merchant_inactive(); }} className="btn-dark">
                Reject Merchant
            </button>
        </div>

    </>)
}


export default MerchantDetailCard;