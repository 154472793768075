
import { useState } from "react";
import logo_path from "./images/logo.png";
import { Link } from "react-router-dom";


function Header(props) {

    const processing_state = props.processing_state;



    return (<>
        <div className="notification-bar">
            <p></p>
        </div>
        <div className="header">
            <a href="/" className="no-decor-link">
                <div className="branding">
                    <img src={logo_path} alt="" />
                    <h4>{props.header_title}</h4>
                </div>
            </a>

            <div className="menu-bar">

            </div>
            {processing_state && <div className="loader-container"><div className="loader"></div></div>}

        </div>

    </>
    );
}

export default Header;