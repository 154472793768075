import { useEffect, useState } from "react";
import { FREQUENCY_CATEGORIES } from "./frequencyClassTemplate";
import { FMCG_CATEGORIES, FMCG_SUB_CATEGORIES } from "./fmcgCategoryTemplate";
import { BRAND_TEMPLATE } from "./brandTemplate";
import { addBrandService } from "./ActionService";
import { PRICE_BRACKETS } from "./priceBracketTemplate";

function AddBrand(props) {


    const [title, setTitle] = useState("");
    const [parent_company, setParentCompany] = useState("");
    const [brand, setBrand] = useState(BRAND_TEMPLATE);
    const [prepareBrand, setPrepareBrand] = useState(BRAND_TEMPLATE);

    var fmcg_Categories = FMCG_CATEGORIES;
    var fmcg_subCategories = FMCG_SUB_CATEGORIES;
    const [fmcgId, setFMCGId] = useState(1);
    const [fmcgSubCategories, setFmcgSubCategories] = useState([]);
    const [fmcgCategories, setFMCGCategories] = useState([]);
    var priceBrackets = PRICE_BRACKETS;

    const [SAVE_BRAND, setSaveBrand] = useState(false);


    var confirmOptions = [{ "_id": "ca_1", "name": "Yes" }, { "_id": "ca_2", "name": "No" }];

    const clearForm = () => {
        setTitle("");
        setParentCompany("");
        setBrand(BRAND_TEMPLATE);
        setPrepareBrand(BRAND_TEMPLATE);
    }

    const saveValues = (e) => {
        // console.log(fmcg_Categories, fmcgSubCategories);
        if (title.length < 3) {
            props.set_toast_message("Please check the entered values");
            return;
        }
        setSaveBrand(true);
        setBrand({ ...brand, name: title.trim(), parent_company: parent_company, iot_20: getValue("sbf_3") == "ca_1" ? true : false, fmcg_category_id: Number(fmcg_Categories.filter(i => i.selected).at(0).id), fmcg_sub_category_id: Number(fmcg_subCategories.filter(i => i.selected).at(0).id), average_price_bracket: Number(priceBrackets.filter(i => i.selected).at(0).id) });
        setPrepareBrand({ ...brand, name: title.trim(), parent_company: parent_company, iot_20: getValue("sbf_3") == "ca_1" ? true : false, fmcg_category_id: Number(fmcg_Categories.filter(i => i.selected).at(0).id), fmcg_sub_category_id: Number(fmcg_subCategories.filter(i => i.selected).at(0).id), average_price_bracket: Number(priceBrackets.filter(i => i.selected).at(0).id) });

    }

    const setFMCGSubCategory = (e) => {
        let selected = e.target.value;

        fmcg_subCategories.map(cat => {
            if (cat.id == selected) {
                cat.selected = true;
            } else {
                cat.selected = false;
            }
        })
        console.log("fmcg_subCategories::", fmcg_subCategories);
    }

    const setFMCGCategory = (e, ind = null) => {
        // props.set_toast_message("FMCG category updated");
        let selected = null;
        if (e) {
            selected = e.target.value;
        }
        if (ind) {
            selected = ind;
        }
        setFMCGId(selected);
        setFmcgSubCategories(FMCG_SUB_CATEGORIES.filter(i => i.parent_id == selected));
        fmcg_subCategories.map(cat => cat.selected = false);
        fmcg_subCategories.forEach(cat => {
            if (cat.parent_id == selected) {
                cat.selected = true;
                return;
            }
        })
        fmcg_Categories.map(cat => {
            if (cat.id == selected) {
                cat.selected = true;
            } else {
                cat.selected = false;
            }
        });
        console.log("ind=", fmcg_Categories);
        setFMCGCategories(fmcg_Categories);



        // console.log(fmcg_Categories);
    }


    const setPriceBracket = (e) => {
        // props.set_toast_message("FMCG category updated");
        let selected = e.target.value;


        priceBrackets.map(cat => {
            if (cat.id == selected) {
                cat.selected = true;
            } else {
                cat.selected = false;
            }
        })

        // console.log(fmcg_Categories);
    }





    const selectOption = (e) => {
        e.preventDefault();
        let p = Array.prototype.slice.call(e.target.parentNode.attributes).find(x => x.localName == "data-select-button");
        let b = Array.prototype.slice.call(e.target.attributes).find(x => x.localName == "data-select-button-val");
        let t = document.querySelector(`[data-select-button=${p.value}]`);
        t.setAttribute("data-selected-val", b.value);
        Array.prototype.slice.call(e.target.parentNode.children).map(c => { c.classList.remove("filled-dark") });
        e.target.classList.add("filled-dark");
    }

    const getValue = (select_field_name) => {
        let t = document.querySelector(`[data-select-button=${select_field_name}]`);
        return t.getAttribute("data-selected-val");
    }


    useEffect(() => {
        // Set Processing state
        props.s_p(false);
        setFMCGCategory(null, 1);
        // props.show_toast(true);
        console.log("updated:", brand);

    }, [brand]);

    useEffect(() => {
        if (SAVE_BRAND) {
            props.s_p(true);
            setSaveBrand(false);
            addBrandService(brand).then(data => {
                console.log(data);
                props.s_p(false);
                // expecting data to return true from backend
                if (data === true) {
                    props.set_toast_message("Brand added successfully");
                    setFMCGCategory(null, 1);
                    clearForm();
                } else {
                    if (data.data.message == "VALIDATION_FAILED") {
                        props.set_toast_message("Validation failed");
                    }
                    if (data.status == 403) {
                        props.set_toast_message("Forbidden action");
                    }
                }

            }).catch(err => {

                console.log("api err:", err);
                switch (err.data.statusCode) {
                    case "VALIDATION_FAILED":
                        props.set_toast_message("Please fill in all the fields");
                        break;
                    case 403:
                        localStorage.removeItem("x-t");
                        props.set_toast_message("Forbidden action");
                    default:
                        props.set_toast_message("Brand not added");
                }
            })
        }

        // }ss
    }, [prepareBrand]);

    return (
        <>
            {/* <h2 className="main-heading">Add new Brand</h2> */}
            <div className="card-container left-aligned margin15 rounded">
                <h3 style={{ fontSize: "1.2em" }}>Add Brand</h3>
                <p className="md-font">List of merchants recently added and not yet approved</p>
            </div>
            <form className="card-container card-form">

                <div className="field">
                    <label>Brand name</label>
                    <p>The company name of the brand</p>
                    <input value={title} onChange={(e) => { setTitle(e.target.value); }} type="text" placeholder="Brand name" />
                </div>
                <div className="field">
                    <label>Parent company name (optional)</label>
                    <p>The parent company of the brand</p>
                    <textarea value={parent_company} onChange={(e) => { setParentCompany(e.target.value); }} type="text" placeholder="Parent company" />
                </div>

                <div className="field">
                    <label>Older than 20 years?</label>
                    <p>Select Yes if the brand exists for more than 20 years</p>
                    <div data-selected-val="ca_2" data-select-button="sbf_3" className="select_button_field">
                        {confirmOptions.map(c => {
                            return <button data-select-button-val={c._id} onClick={(e) => { selectOption(e) }} className={`btn btn-dark ${c._id == "ca_2" ? "filled-dark" : ""} select-button`}>{c.name}</button>
                        })}
                    </div>
                </div>
                <div className="field">
                    <label>FMCG category</label>
                    <p>FMCG category of the brand</p>
                    <select value={fmcgId} onChange={(e) => setFMCGCategory(e, null)} placeholder="Choose a category">
                        {
                            fmcgCategories.map(cate => {
                                return <option value={cate.id} defaultValue={cate.selected}>{cate.name}</option>
                            })
                        }
                    </select>
                </div>
                <div className="field">
                    <label>FMCG sub-category</label>
                    <p>FMCG sub-category of the brand</p>
                    <select onChange={(e) => setFMCGSubCategory(e)} placeholder="Choose a sub-category">
                        {
                            fmcgSubCategories.map(cat => {
                                return <option value={cat.id} defaultValue={cat.selected}>{cat.name}</option>
                            })
                        }
                    </select>
                </div>
                <div className="field">
                    <label>Average Price Bracket (<span style={{ fontSize: "15px" }}>&#8377;</span>)</label>
                    <p>Average price bracket of the brand (<span style={{ fontSize: "18px" }}>&#8377;</span>)</p>
                    <select onChange={(e) => setPriceBracket(e)} placeholder="Choose a bracket">
                        {
                            priceBrackets.map(cat => {
                                return <option value={cat.id} defaultValue={cat.selected}>{cat.name}</option>
                            })
                        }
                    </select>
                </div>



                {!SAVE_BRAND && <button className="btn-dark" onClick={(e) => { e.preventDefault(); saveValues() }} >Add Brand</button>}
                {SAVE_BRAND && <button className="btn-dark disabled-state" onClick={(e) => { e.preventDefault(); }} >Adding..</button>}
            </form>
        </>
    )
}


export default AddBrand;