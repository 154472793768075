export const FMCG_CATEGORIES = [
    { "id": 1, "name": "Food & Beverages", "selected": true },
    { "id": 2, "name": "Personal Care & Hygiene", "selected": false },
    { "id": 3, "name": "Household Cleaning & Care", "selected": false },
    { "id": 4, "name": "Health & Wellness", "selected": false },
    { "id": 5, "name": "Baby & Childcare", "selected": false },
    { "id": 6, "name": "Pet Care", "selected": false },
    { "id": 7, "name": "Confectionery & Chocolates", "selected": false },
    { "id": 9, "name": "Office & School Supplies", "selected": false },
    { "id": 10, "name": "Home Decor", "selected": false },
    { "id": 11, "name": "Beauty & Cosmetics", "selected": false },
    { "id": 12, "name": "Pharmaceuticals", "selected": false },
    { "id": 13, "name": "MISC", "selected": false }
];

export const FMCG_SUB_CATEGORIES = [
    { "parent_id": 1, "id": 1, "name": "Packaged Food Items", "selected": true },
    { "parent_id": 1, "id": 2, "name": "Snacks", "selected": false },
    { "parent_id": 1, "id": 3, "name": "Soft Drinks", "selected": false },
    { "parent_id": 1, "id": 4, "name": "Juices", "selected": false },
    { "parent_id": 1, "id": 5, "name": "Dairy", "selected": false },
    { "parent_id": 1, "id": 6, "name": "Meat", "selected": false },
    { "parent_id": 2, "id": 7, "name": "Toiletries", "selected": false },
    { "parent_id": 2, "id": 8, "name": "Skincare", "selected": false },
    { "parent_id": 2, "id": 9, "name": "Haircare", "selected": false },
    { "parent_id": 2, "id": 10, "name": "Oral care", "selected": false },
    { "parent_id": 3, "id": 11, "name": "Laundry Detergents", "selected": false },
    { "parent_id": 3, "id": 12, "name": "Cleaning Agents", "selected": false },
    { "parent_id": 3, "id": 13, "name": "Air Fresheners", "selected": false },
    { "parent_id": 3, "id": 14, "name": "Disinfectants", "selected": false },
    { "parent_id": 4, "id": 15, "name": "Over-the-Counter Medicines", "selected": false },
    { "parent_id": 4, "id": 16, "name": "Vitamins & Supplements", "selected": false },
    { "parent_id": 4, "id": 17, "name": "Health Drinks", "selected": false },
    { "parent_id": 5, "id": 18, "name": "Diapers", "selected": false },
    { "parent_id": 5, "id": 19, "name": "Baby Foods", "selected": false },
    { "parent_id": 5, "id": 20, "name": "Baby Skin Care", "selected": false },
    { "parent_id": 6, "id": 21, "name": "Pet Food & Care", "selected": false },
    { "parent_id": 7, "id": 22, "name": "Confectionery and Chocolates", "selected": false },
    { "parent_id": 10, "id": 23, "name": "Home Decor Items", "selected": false },
    { "parent_id": 11, "id": 24, "name": "Makeups/Perfumes", "selected": false },
    { "parent_id": 11, "id": 25, "name": "Hair Styling", "selected": false },
    { "parent_id": 12, "id": 26, "name": "Pharmaceuticals", "selected": false },
    { "parent_id": 9, "id": 27, "name": "Office & School Supplies", "selected": false },
    { "parent_id": 13, "id": 28, "name": "Multiple", "selected": false },
    { "parent_id": 11, "id": 29, "name": "Face cream", "selected": false },
    { "parent_id": 12, "id": 30, "name": "Antiseptics", "selected": false },
    { "parent_id": 12, "id": 31, "name": "Syrups", "selected": false },
    { "parent_id": 1, "id": 32, "name": "Pulses", "selected": false },
    { "parent_id": 1, "id": 33, "name": "Spices", "selected": false },
    { "parent_id": 1, "id": 34, "name": "Grains", "selected": false },
]

