import { useState } from "react";
import { login } from "./actions/ActionService";

import { useNavigate } from "react-router-dom";


function LoginScreen(props) {

    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const login_account = () => {
        if (password.length < 2) {
            props.set_toast_message("Enter a valid password");
            return;
        }
        props.s_p(true);
        login({ masked_password: password }).then(data => {
            console.log(data);
            props.s_p(false);
            switch (data.result) {
                case 'LOGIN_FAILED':
                    props.set_toast_message("Invalid credentials");
                    break;
                case 'LOGIN_SUCCESS':
                    localStorage.setItem("x-t", data.access_token);
                    window.location.reload();
                    break;
                default:
                    break;
            }
        }).catch(err => {
            props.s_p(false);
            console.log(err);
        })
    }

    return (
        <>
            <div className="card-container" style={{ justifyContent: "center", height: "100%" }}>
                <h3 style={{ textAlign: "center" }}>Welcome Admin!</h3>
                <h2 className="main-heading">Admin Login</h2>
                {/* <p>Please enter the password provided by the System Admin</p><br/> */}
                <form style={{ height: "fit-content", width: "100%", marginLeft: "0" }} className="card-container card-form">
                    <p style={{ fontWeight: "bold" }}>Master Admin</p><br />
                    <div className="field">
                        <label>Enter password</label>
                        <p>Please enter the password provided by the sys admin</p>
                        <input onChange={(e) => { setPassword(e.target.value) }} type="password" placeholder="Password" />
                    </div>
                    <button onClick={(e) => { e.preventDefault(); login_account(); }} className="btn-dark">Log in</button>
                </form>
            </div>
        </>
    )
}


export default LoginScreen;