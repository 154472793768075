import { BrowserRouter, Route, Routes, HashRouter } from 'react-router-dom';


import Header from './Header';
import SplashScreen from './SplashScreen';
import { useEffect, useState } from 'react';
import IntroScreen from './IntroScreen';
import AuthorizationRequired from './AuthorizationRequired';

import Toast from './Toast';
import AddProduct from './actions/AddProduct';

import AddBrand from './actions/AddBrand';
import AddVariant from './actions/AddVariant';
import LoginScreen from './LoginScreen';
import ViewCatalogue from './actions/ViewCatalogue';
import OnboardingForm from './actions/merchant/onboarding/OnboardingForm';
import ListDraftMerchants from './actions/merchant/onboarding/ListDraftMerchants';
import MerchantDetailCard from './actions/merchant/onboarding/MerchantDetailCard';
import CreateStore from './actions/store/CreateStore';

function App() {

  // Page loading state
  const [isLoading, setLoading] = useState(true);

  // Set Header title
  const [headerTitle, setHeaderTitle] = useState("Operations Panel");

  // Background process state
  const [isProcessing, setIsProcssing] = useState(false);

  // Splash screen state
  const [showSplash, setShowSplash] = useState(false);

  // check for authentication
  const [token, setToken] = useState(null);

  // Toast state
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);




  useEffect(() => {
    if (localStorage.getItem("x-t") != undefined) {
      setToken(localStorage.getItem("x-t"));
    } else {

    }

    // Splash screen timeout
    setTimeout(() => {
      setShowSplash(false);
    }, 2100);
  }, []);


  useEffect(() => {
    if (toastMessage.length > 0) {
      setShowToast(true);
      setTimeout(() => { setShowToast(false); setToastMessage("") }, 1900);
    }
  }, [toastMessage]);


  return (
    <>
      {!showSplash && <>
        <Header header_title={headerTitle} processing_state={isProcessing} />
      </>}
      <div className='parent'>
        {/* <Toast message="Test message" /> */}
        {showToast && <Toast message={toastMessage} />}
        <HashRouter>
          <Routes>
            
            {/* Product routes */}
            <Route path="/" >
              {token == null && <Route path='' index element={<LoginScreen set_toast_message={setToastMessage} s_p={setIsProcssing} />} />}
              <Route path='' index element={<IntroScreen />} />
              <Route path='/add-a-product' element={<AddProduct set_toast_message={setToastMessage} s_p={setIsProcssing} />} />
              <Route path='/add-a-brand' element={<AddBrand set_toast_message={setToastMessage} s_p={setIsProcssing} />} />
              <Route path='/add-a-variant' element={<AddVariant set_toast_message={setToastMessage} s_p={setIsProcssing} />} />
              <Route path='/view/catalogue' element={<ViewCatalogue set_toast_message={setToastMessage} s_p={setIsProcssing} />} />
            </Route>
            {/* Product routes end */}

            {/* Merchant routes start */}
            <Route path="/merchant">
              {token == null && <Route path='' index element={<LoginScreen set_toast_message={setToastMessage} s_p={setIsProcssing} />} />}
              <Route path="/merchant/onboard" index element={<OnboardingForm set_toast_message={setToastMessage} s_p={setIsProcssing} />} />
              <Route path="/merchant/in-review/list" element={<ListDraftMerchants set_toast_message={setToastMessage} s_p={setIsProcssing} />} />
              <Route path='/merchant/in-review/edit/:mask_id' element={<MerchantDetailCard set_toast_message={setToastMessage} s_p={setIsProcssing} />} />
            </Route>
            {/* Merchant routes end */}

            {/* Store routes start */}
            <Route path='/store'>
              <Route path='/store/create' index element={<CreateStore set_toast_message={setToastMessage} s_p={setIsProcssing} p_s={isProcessing} />} />
            </Route>
            {/* Store routes end */}

          </Routes>
        </HashRouter>
      </div>
      {showSplash && <SplashScreen />}


    </>
  );
}

export default App;
