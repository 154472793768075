export function isInViewport(element, offset = 0) {

    const top = element.getBoundingClientRect().top;
    return (top + offset) >= 0 && (top - offset) <= (window.innerHeight + 90);
}


export const convertISOStringToMonthDay = date => {
    const tempDate = new Date(date).toString().split(' ');
    const formattedDate = `${tempDate[1]} ${+tempDate[2]} ${+tempDate[3]}`;
    return formattedDate;
};


export const generateFileId = entity => {
    let suffix_ = "-kd";

    switch (entity) {
        case "merchant_address_proof":
            suffix_ = "-m_a-kd";
            break;
        case "merchant_id_proof":
            suffix_ = "-m_id-kd";
            break;
        case "product_thumbnail":
            suffix_ = "-pr-kd"
            break;
        case "store_icon":
            suffix_ = "-st-ic"
        default:
            break;
    }

    let segment_1 = new Date().getTime().toString().slice(5);

    return segment_1 + suffix_;
}