export const stepOneDTO = {
    // "store_mask_id": "",
    "owner_id": ""
}

export const stepTwoDTO = {
    "store_mask_id": "",
    "store_address": "",
    "coordinates": {
        "latitude": -1,
        "longitude": -1
    },
    "metadata":{}
}

export const stepThreeDTO = {
    "store_mask_id": "",
    "fmcg_sub_category_ids": [],
    "product_unavailable_ids": []
}

export const stepFourDTO = {
    "store_mask_id":"",
    "inventory_level_array":[],
    "entity_type":"FMCG_SUB_CATEGORY",
    "entity_id_array":[]
}

export const stepFiveDTO = {
    "store_mask_id":"",
    "fmcg_sub_category_cost_adjustment_mapping":[],
    "product_cost_adjustment_mapping":[]
}