
// for frontend reference only
export const PRODUCT_TEMPLATE = {
    "name": "",
    "name_alias": "",
    "metadata": "{}",
    "variant": null,
    "max_retail_price": 0,
    "fmcg_sub_category_id": -1,
    "frequency_class": -1,
    "brand": -1,
    "package_state": null,
    "thumbnail_url": "",
    "is_bundled":false,
    "item_metrics": {"unit":1, "weight":0, "quantity":1},
    "availability_segment":"THROUGHOUT"
}

