import { useEffect, useRef, useState } from "react";
import { PRODUCT_TEMPLATE } from "./productTemplate";
import QuantitySelector from "./QuantitySelector";
import { fetchVariants } from "./variantTemplate";
import { FREQUENCY_CATEGORIES } from "./frequencyClassTemplate";
import { FMCG_CATEGORIES, FMCG_SUB_CATEGORIES } from "./fmcgCategoryTemplate";
import { fetchBrands } from "./brandTemplate";
import { addToProductService, fetchProductsService, searchProductsService } from "./ActionService";
import { isInViewport } from "../utils";
import spinner from "../images/spinner.gif";
import ProductDetailCard from "./ProductDetailCard";
import { set } from "mongoose";


function ViewCatalogue(props) {

    const [catalogue, setCatalogueProducts] = useState([]);
    const [selected, setSelected] = useState(false);
    
    // filter
    const [search_query, setSearchQuery] = useState(null);
    const [isSearching, setSearching] = useState(false);

    var ref_eor = useRef(null);
    var ref_container = useRef(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [resultCount, setResultsCount] = useState(0);
    const [brandsCount, setResultsBrandsCount] = useState(0);
    const DOCS_LIMIT = 25;
    
    var break_ = true;

    const get_products = async () => {
        await fetchProductsService({ "page": currentPage }).then(data => {
            if (data.status == 403) {
                localStorage.removeItem("x-t");
                window.location.href = "/";
                props.set_toast_message("Forbidden");
            }
            props.s_p(false);
            if (!(data["products.data"])) return;
            let new_data = data["products.data"];
            if (!isSearching) {
                console.log("concatenating...");
                setCatalogueProducts(catalogue.concat(new_data));
            } else {
                console.log("replacing..");
                setCatalogueProducts(new_data);
                setSearching(false);
            }
            ref_eor.current.style.opacity = "0";
            ref_eor.current.style.mixBlendMode = "multiply";
            setResultsCount(data['meta_total_count_products']);
            setResultsBrandsCount(data['meta_total_count_brands']);
            // console.log("result count", resultCount);
            props.s_p(false);
        })
    }
    useEffect(() => {
        props.s_p(true);
        console.log("Current page changed to=", currentPage);
        // console.log("fetching new data");
        ref_eor.current.style.opacity = "0";
        ref_eor.current.style.mixBlendMode = "multiply";


        if (search_query != null && search_query.length == 0) {
            console.log("fetching products 1");
            get_products();
        }
        else if (search_query != null && search_query.length > 0) {
            props.s_p(false);
            console.log("changing current_page to 1");
            // setCurrentPage(1);
            // setResultsCount(0);
            // setResultsBrandsCount(0);
        } else {
            console.log("fetching products 2");
            get_products();
        }
    }, [currentPage]);



    useEffect(() => {
        console.log("resultCount changed_to__", resultCount);
    }, [resultCount]);
    // useEffect(() => {
    //     console.log(catalogue);
    // }, [catalogue]);

    useEffect(() => {
        console.log("adding event listener");
        // console.log("resultsCount=", resultCount);
        // console.log("ref_eor value:", ref_eor);
        if (ref_eor && ref_container) {
            ref_container.addEventListener("scroll", (e) => {
                ref_eor.current.style.opacity = "0";
                if (isInViewport(ref_eor.current)) {
                    // console.log("currentPage__:", currentPage);
                    let pages = Math.round(resultCount / DOCS_LIMIT);
                    let balance = resultCount % DOCS_LIMIT;
                    // console.log("pages=", pages, "balance=", balance);
                    if ((currentPage <= pages) || ((DOCS_LIMIT - balance) < 3)) {
                        ref_eor.current.style.opacity = "1";
                        ref_eor.current.style.mixBlendMode = "multiply";
                        let new_page = currentPage + 1;
                        // console.log("newPage:", new_page);
                        if (!break_) {
                            setCurrentPage(currentPage => {
                                return currentPage + 1;
                            });
                        };
                        break_ = true;
                        // console.log("updating current page==", currentPage);
                    } else {
                        console.log("pages-", pages, "currentPage-", currentPage)
                        console.log("Not in view");
                        ref_eor.current.style.opacity = "0";
                    }
                } else {
                    // console.log("NOT_IN_VIEW");
                    break_ = false;
                }

            })
        }
    }, [ref_eor, ref_container, resultCount]);


    useEffect(() => {
        const search_products = async () => {
            setSearching(true);
            props.s_p(true);
            await searchProductsService({ "query": search_query }).then(data => {
                props.s_p(false);
                if (data.status == 403) {
                    localStorage.removeItem("x-t");
                    props.set_toast_message("Forbidden");
                    window.location.href = "/";
                }
                if (!data["products.data.search"]) return;
                let search_results = data["products.data.search"];
                if (search_results.length > 0) setSearching(false);
                setCatalogueProducts(search_results);

            })
        }

        // console.log("QUERY::", search_query.length);
        if (search_query != null) {
            if (search_query.length == 0) {
                get_products();
            }
        }
        if (search_query) {
            if (search_query.length > 2) {
                search_products();
            }

        }
    }, [search_query]);

    return (
        <>
            {selected && <ProductDetailCard set_toast_message = {props.set_toast_message} s_p={props.s_p} s_s={setSelected} selectedProduct={selected} catalogue={catalogue} set_catalogue={setCatalogueProducts} results_count={resultCount} set_results_count={setResultsCount} />}
            <div className="card-container left-aligned margin15 rounded">
                <h3 style={{ fontSize: "1.2em" }}>Catalogue</h3><br />
                <p>
                    Total Products: <span style={{ fontWeight: "bold", fontSize: "20px" }}>{resultCount}</span>
                </p>
                <p>
                    Total Brands: <span style={{ fontWeight: "bold", fontSize: "20px" }}>{brandsCount}</span>
                </p>
            </div>
            <div style={{ padding: "7px" }} className="card-container left-aligned margin15 rounded">
                <div className="filter">
                    <div className="search-box card-form">
                        <div>
                            <input onChange={(e) => { setSearchQuery(e.target.value); setCurrentPage(1); setCatalogueProducts([]); setSearching(true) }} style={{ width: "90%" }} type="text" placeholder="search here" />
                            <i style={{ fontSize: "1em", padding: "0px" }} className="fas fa-search"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div className="overflow-container" ref={node => ref_container = node}>

                {isSearching && <div style={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: "100px" }}><img width={"50px"} src={spinner} /></div>}
                {catalogue &&
                    catalogue.map(catalogue_item => {
                        var product = PRODUCT_TEMPLATE;
                        product = { ...catalogue_item };
                        return <div onClick={() => { setSelected(catalogue_item) }} className="catalogue-tile card-container row">
                            <img src={(product.thumbnail_url && product.thumbnail_url != "no_image") ? product.thumbnail_url : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzRKni2nZ5o_isZotHkFMrIGFfY3X8kqBQ4A&usqp=CAU"} />
                            <div className="info">
                                <h4 style={{ textWrap: "balance" }}>{product.name}</h4>
                                <span style={{ marginTop: "5px", fontSize: "0.85rem", color: "#27888b" }}>{product.brand ? product.brand.name : ""}</span>
                                <span style={{ fontSize: "0.725rem", color: "#d7007cd1" }} >{FMCG_CATEGORIES.find(cat => cat.id == product.brand.fmcg_category_id).name}</span>
                                <span style={{ fontSize: "0.725rem", color: "navy" }} >{FMCG_SUB_CATEGORIES.find(cat => cat.id == product.brand.fmcg_sub_category_id).name}</span>
                            </div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div className="mrp">
                                <span>&#8377;</span>&nbsp;{product.max_retail_price}
                            </div>
                            <div></div>
                        </div>

                    })
                }
                <div ref={ref_eor} id="e_o_r">
                    <img src={spinner} />
                </div>
                <div></div>
                <div></div>
                <div>&nbsp;</div>
            </div>
        </>
    )
}






export default ViewCatalogue;


