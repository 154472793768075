import axios from "axios"
import Params from "../constants"


const auth_headers = Params.AXIOS_AUTH_HEADERS(localStorage.getItem("x-t"));

// Product services

export const addToProductService = (product) => {
    console.log(product);
    return axios.post(Params.OPS_SERVICE_BASE_URL + Params.ADD_PRODUCT, product, auth_headers).then(res => res.data).catch(err => err.response);
}

export const fetchProductsService = (body) => {
    return axios.post(Params.OPS_SERVICE_BASE_URL + Params.FETCH_PRODUCTS, body, auth_headers).then(res => res.data).catch(err => err.response);
}

export const searchProductsService = (body) => {
    return axios.post(Params.OPS_SERVICE_BASE_URL + Params.SEARCH_PRODUCTS, body, auth_headers).then(res => res.data).catch(err => err.response);
}

export const addBrandService = async (body) => {

    return axios.post(Params.OPS_SERVICE_BASE_URL + Params.ADD_BRAND, body, auth_headers).then((res) => {
        return res.data
    }).catch(err => err.response);
}

export const addVariantService = async (body) => {

    return axios.post(Params.OPS_SERVICE_BASE_URL + Params.ADD_VARIANT, body, auth_headers).then((res) => {
        return res.data
    }).catch(err => err.response);
}

export const getFmcgSubCategoriesWithCount = async (body) => {
    return axios.post(Params.OPS_SERVICE_BASE_URL + Params.GET_FMCG_SUB_CAT_COUNT, body, auth_headers).then(res => {
        return res.data;
    }).catch(err => err.response);
}

export const getProductsByFmcgSubCategory = async (body) => {
    return axios.post(Params.OPS_SERVICE_BASE_URL + Params.GET_PRODUCTS_BY_FMCG_SUB_CAT, body, auth_headers).then(res => {
        return res.data;
    }).catch(err => err.response);
}


export const updateProduct = async (body) => {
    return axios.post(Params.OPS_SERVICE_BASE_URL + Params.UPDATE_PRODUCT, body, auth_headers).then((res) => {
        return res.data
    }).catch(err => err.response);
}

export const login = async (body) => {
    return axios.post(Params.OPS_SERVICE_BASE_URL + Params.LOGIN_INITIATE, body).then((res) => {
        return res.data
    }).catch(err => err.response);
}


// Merchant Services

export const submitMerchantForReview = async (body) => {

    return axios.post(Params.OPS_SERVICE_BASE_URL + Params.ADD_MERCHANT, body, auth_headers).then((res) => {
        return res.data
    }).catch(err => err.response);
}

export const getActiveMerchants = async (body) => {

    return axios.post(Params.OPS_SERVICE_BASE_URL + Params.FETCH_ACTIVE_MERCHANTS, body, auth_headers).then((res) => {
        return res.data
    }).catch(err => err.response);
}

export const markMerchantActive = async (body) => {

    return axios.put(Params.OPS_SERVICE_BASE_URL + Params.MARK_MERCHANT_ACTIVE, body, auth_headers).then((res) => {
        return res.data
    }).catch(err => err.response);
}

export const markMerchantInActive = async (body) => {

    return axios.put(Params.OPS_SERVICE_BASE_URL + Params.MARK_MERCHANT_INACTIVE, body, auth_headers).then((res) => {
        return res.data
    }).catch(err => err.response);
}


// Merchant Services end

// Store services

export const storeStepOne = async (body) => {
    return axios.post(Params.OPS_SERVICE_BASE_URL + Params.ONBOARDING_STEP_ONE, body, auth_headers).then((res) => {
        return res.data
    }).catch(err => err.response);
}

export const storeStepTwo = async (body) => {
    return axios.put(Params.OPS_SERVICE_BASE_URL + Params.ONBOARDING_STEP_TWO, body, auth_headers).then((res) => {
        return res.data
    }).catch(err => err.response);
}

export const storeStepThree = async (body) => {
    return axios.put(Params.OPS_SERVICE_BASE_URL + Params.ONBOARDING_STEP_THREE, body, auth_headers).then((res) => {
        return res.data
    }).catch(err => err.response);
}

export const storeStepFour = async (body) => {
    return axios.put(Params.OPS_SERVICE_BASE_URL + Params.ONBOARDING_STEP_FOUR, body, auth_headers).then((res) => {
        return res.data
    }).catch(err => err.response);
}

export const storeStepFive = async (body) => {
    return axios.put(Params.OPS_SERVICE_BASE_URL + Params.ONBOARDING_STEP_FIVE, body, auth_headers).then((res) => {
        return res.data
    }).catch(err => err.response);
}

export const storeMarkForReview= async (body)=>{
    return axios.post(Params.OPS_SERVICE_BASE_URL + Params.ONBOARDING_MARK_REVIEW, body, auth_headers).then((res) => {
        return res.data
    }).catch(err => err.response);
}

// Store services end


// Utils services

export const uploadFile = async (form_data) => {

    auth_headers["Content-Type"] = "multipart/form-data";

    return axios.post(Params.OPS_SERVICE_BASE_URL + Params.UTILS_UPLOAD_FILE, form_data, auth_headers).then((res) => {
        return res.data
    }).catch(err => err.response);
}

export const getTextFromImage = async (form_data) => {

    auth_headers["Content-Type"] = "multipart/form-data";

    return axios.post(Params.OPS_SERVICE_BASE_URL + Params.UTILS_TEXT_FROM_IMAGE, form_data, auth_headers).then((res) => {
        return res.data
    }).catch(err => err.response);
}

// export const uploadFile = async()

// Utils services end

