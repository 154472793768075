import { useState, useEffect } from "react";
import { MERCHANT_TEMPLATE } from "../../merchantTemplate";
import Params, { OPERATION_PARAMETERS } from "../../../constants";
import { getValue } from "firebase/remote-config";
import { submitMerchantForReview, uploadFile } from "../../ActionService";
import { generateFileId } from "../../../utils";




function OnboardingForm(props) {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [storeName, setStoreName] = useState("");
    const [storeLegalName, setStoreLegalName] = useState("");
    const [storeAge, setStoreAge] = useState(1);
    const [storeArea, setStoreArea] = useState("");
    const [gstNumber, setGstNumber] = useState("");
    const [idProofSrcUrl, setIdProofSrcUrl] = useState("");
    const [addressProofSrcUrl, setAddressProofSrcUrl] = useState("");

    // docs to upload
    const [idProof, setIdProof] = useState(undefined);
    const [addressProof, setAddressProof] = useState(undefined);

    const [merchant, setMerchant] = useState(MERCHANT_TEMPLATE);
    const [prepareMerchant, setPrepareMerchant] = useState(MERCHANT_TEMPLATE);

    const [SUBMIT_REVIEW, setSubmitReview] = useState(false);

    var unitOptions = [{ "_id": "ca_1", "name": "sq. feet" }, { "_id": "ca_2", "name": "Gaj" }];


    const clearForm = () => {
        setFirstName("");
        setLastName("");
        setStoreAge(1);
        setStoreName("");
        setStoreLegalName("");
        setStoreArea("");
        setGstNumber("");
        setMerchant(MERCHANT_TEMPLATE);
        setPrepareMerchant(MERCHANT_TEMPLATE);
        setSubmitReview(false);
        setIdProofSrcUrl("");
        setAddressProofSrcUrl("");
        props.s_p(false);
    }

    const getValue = (select_field_name) => {
        let t = document.querySelector(`[data-select-button=${select_field_name}]`);
        return t.getAttribute("data-selected-val");
    }

    const selectOption = (e) => {
        e.preventDefault();
        let p = Array.prototype.slice.call(e.target.parentNode.attributes).find(x => x.localName == "data-select-button");
        let b = Array.prototype.slice.call(e.target.attributes).find(x => x.localName == "data-select-button-val");
        let t = document.querySelector(`[data-select-button=${p.value}]`);
        t.setAttribute("data-selected-val", b.value);
        Array.prototype.slice.call(e.target.parentNode.children).map(c => { c.classList.remove("filled-dark") });
        e.target.classList.add("filled-dark");
    }

    const saveValues = () => {
        // validations
        if (!(firstName.length > 3 && storeName.length > 4 && storeLegalName.length > 4 && storeAge > OPERATION_PARAMETERS.minimum_store_age && storeAge < 50)) {
            props.set_toast_message("Please check the entered values");
            return;
        }
        if (getValue("sbf_1") == "ca_1") {
            if (!(storeArea > OPERATION_PARAMETERS.minimum_area_sq_feet)) {
                props.set_toast_message("Please check the entered area");
                return;
            }
        }

        if (idProofSrcUrl == "" || addressProofSrcUrl == "") {
            props.set_toast_message("Please attach both the documents");
            return;
        }
        if (getValue("sbf_1") == "ca_2") {
            if (!(storeArea > OPERATION_PARAMETERS.minimum_area_gaj)) {
                props.set_toast_message("Please check the entered area");
                return;
            }
        }
        props.s_p(true);
        setSubmitReview(true);
        setMerchant({ ...merchant, owner_first_name: firstName.trim(), owner_first_name: lastName.trim(), store_name: storeName.trim(), store_legal_name: storeLegalName.trim(), store_age: Number(storeAge), store_area: { "value": Number(storeArea), "unit": getValue("sbf_1") ? 1 : 2 }, gst_details: { "gstin": gstNumber }, id_proof_src_url: idProofSrcUrl, address_proof_src_url: addressProofSrcUrl });
        setPrepareMerchant({ ...merchant, owner_first_name: firstName.trim(), owner_first_name: lastName.trim(), store_name: storeName.trim(), store_legal_name: storeLegalName.trim(), store_age: Number(storeAge), store_area: { "value": Number(storeArea), "unit": getValue("sbf_1") ? 1 : 2 }, gst_details: { "gstin": gstNumber }, id_proof_src_url: idProofSrcUrl, address_proof_src_url: addressProofSrcUrl });
        // validations end
    }

    useEffect(() => {
        // Set Processing state
        props.s_p(false);
        console.log("updated:", merchant);

    }, [merchant]);

    useEffect(() => {
        // console.log(idProof);
        if (!idProof) {
            // props.set_toast_message("Please try again");
            return;
        }

        const file_id = generateFileId("merchant_id_proof");


        let uploadIdProof = async () => {

            var fd = new FormData();
            fd.append("file", idProof);
            fd.append("file_name", file_id);

            await uploadFile(fd).then(data => {
                switch (data.code) {
                    case "FILE_UPLOADED":
                        setIdProofSrcUrl(Params.S3_BUCKET_URL + file_id + ".png");
                        props.set_toast_message("File uploaded");
                        break;
                    default:
                        props.set_toast_message("An error occurred");

                }
            })
        }

        uploadIdProof();

    }, [idProof]);

    useEffect(() => {
        // console.log(idProof);
        if (!addressProof) {
            // props.set_toast_message("Please try again");
            return;
        }
        const file_id = generateFileId("merchant_address_proof");


        let uploadAddressProof = async () => {
            var fd = new FormData();
            fd.append("file", addressProof);
            fd.append("file_name", file_id);

            await uploadFile(fd).then(data => {
                switch (data.code) {
                    case "FILE_UPLOADED":
                        setAddressProofSrcUrl(Params.S3_BUCKET_URL + file_id + ".png");
                        props.set_toast_message("File uploaded");
                        break;
                    default:
                        props.set_toast_message("An error occurred");

                }
            })
        }

        uploadAddressProof();

    }, [addressProof]);

    useEffect(() => {
        if (SUBMIT_REVIEW) {
            props.s_p(true);
            setSubmitReview(false);
            submitMerchantForReview(merchant).then(data => {
                props.s_p(false);
                console.log("data:", data);
                if (data && data.status) {
                    switch (data.code) {
                        case "MERCHANT_SUBMITTED":
                            if (data.status == "OPERATION_SUCCESSFUL") {
                                props.set_toast_message("Merchant submitted for review");
                                //
                                //  clear form in case of successful operation
                                clearForm();
                            }
                            break;
                        case "ISSUE_IN_ONBOARDING":
                            props.set_toast_message("An error occurred");
                            break;
                        default:
                            props.set_toast_message("Uknown exception");

                    }
                }

                if (data.data && data.data.statusCode && data.data.statusCode >= 400 && data.data.statusCode <= 500) {
                    switch (data.data.message) {
                        case "VALIDATION_FAILED":
                            props.set_toast_message("Please fill in all the fields");
                            break;
                        case 403:
                            localStorage.removeItem("x-t");
                            props.set_toast_message("Forbidden action");
                        default:
                            props.set_toast_message("Merchant not added");
                    }
                }
            }).catch(err => {

                console.log("api err:", err);
                // DEPRECATED
                switch (err.data.statusCode) {
                    case "VALIDATION_FAILED":
                        props.set_toast_message("Please fill in all the fields");
                        break;
                    case 403:
                        localStorage.removeItem("x-t");
                        props.set_toast_message("Forbidden action");
                    default:
                        props.set_toast_message("Merchant not added");
                }
            })

        }
    }, [prepareMerchant]);

    return (<>

        <form className="card-container card-form">
            <h2 className="main-heading">Merchant Onboarding</h2>
            <hr />
            <br />

            <div className="field">
                <label>First name</label>
                <p>Merchant's first name</p>
                <input value={firstName} onChange={(e) => { setFirstName(e.target.value); }} type="text" placeholder="First name" />
            </div>
            <div className="field">
                <label>Last name</label>
                <p>Merchant's last name</p>
                <input value={lastName} onChange={(e) => { setLastName(e.target.value); }} type="text" placeholder="Last name" />
            </div>
            <div className="field">
                <label>Store name</label>
                <p>Store owned by the merchant</p>
                <input value={storeName} onChange={(e) => { setStoreName(e.target.value); }} type="text" placeholder="Store name" />
            </div>
            <div className="field">
                <label>Registered store name</label>
                <p>Registered name of the store</p>
                <input value={storeLegalName} onChange={(e) => { setStoreLegalName(e.target.value); }} type="text" placeholder="Store registered name" />
            </div>
            <div className="field">
                <label>Years of operation</label>
                <p>Number of years the store has been operating in the same area</p>
                <input value={storeAge} onChange={(e) => { setStoreAge(e.target.value); }} type="number" placeholder="Years of operation" />
            </div>
            <div className="field">
                <label>Store area</label>
                <p>Area of the registered store</p>
                <input value={storeArea} onChange={(e) => { setStoreArea(e.target.value); }} type="text" placeholder="Store area" />
            </div>
            <div className="field">
                <label>Unit of area</label>
                <p>Unit of the above mentioned area</p>
                <div data-selected-val="ca_1" data-select-button="sbf_1" className="select_button_field">
                    {unitOptions.map(c => {
                        return <button data-select-button-val={c._id} onClick={(e) => { selectOption(e) }} className={`btn btn-dark ${c._id == "ca_2" ? "filled-dark" : ""} select-button`}>{c.name}</button>
                    })}
                </div>
            </div>
            <div className="field">
                <label>GST No.</label>
                <p>Goods & Services Tax number</p>
                <input value={gstNumber} onChange={(e) => { setGstNumber(e.target.value); }} type="text" placeholder="GST number" />
            </div>
            <div className="field">
                <label>ID Proof</label>
                <p>Please upload ID proof- AADHAAR Card</p>
                <input onChange={(e) => setIdProof(e.target.files[0])} type="file" />
            </div>
            <div className="field">
                <label>Address Proof</label>
                <p>Please upload address proof- Electricity bill</p>
                <input onChange={(e) => setAddressProof(e.target.files[0])} type="file" />
            </div>
            {!SUBMIT_REVIEW && <button className="btn-dark" onClick={(e) => { e.preventDefault(); saveValues(); }} >Submit for review</button>}
            {SUBMIT_REVIEW && <button className="btn-dark disabled-state" onClick={(e) => { e.preventDefault(); }} >Submitting..</button>}
        </form>
    </>)
}


export default OnboardingForm;