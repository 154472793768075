import { useEffect, useState } from "react";
import { getActiveMerchants, getFmcgSubCategoriesWithCount, getProductsByFmcgSubCategory, storeMarkForReview, storeStepFive, storeStepFour, storeStepOne, storeStepThree, storeStepTwo, uploadFile } from "../ActionService";
import { FMCG_SUB_CATEGORIES } from "../fmcgCategoryTemplate";
import { costAdjustmentMapping } from "../costAdjustmentMapping";
import Params from "../../constants";
import { stepFiveDTO, stepFourDTO, stepOneDTO, stepThreeDTO, stepTwoDTO } from "./storeDAO";
import { Link } from "react-router-dom";
import spinner from "../../images/spinner.gif";
import { generateFileId } from "../../utils";


function CreateStore(props) {


    const [activeMerchants, setActiveMerchants] = useState([]);
    const [step, setStep] = useState(1);
    const [fetchingLocation, setFetchingLocation] = useState(false);
    const [fmcgCatsCount, setFmcgCatsCount] = useState([]);
    // store parameters
    const [merchant_mask_id, setMerchantMaskId] = useState("");
    const [store_mask_id, setStoreMaskId] = useState("");
    const [store_icon_file, setStoreIconFile] = useState(null);
    const [store_icon_url, setStoreIconUrl] = useState("");
    const [store_address, setAddress] = useState("");
    const [locationCoords, setLocationCoords] = useState(null);
    const [fmcgSubCat, setFmcgSubCat] = useState([]);
    const [tempFmcgSubCat, setTempFmcgSubCat] = useState([]);
    const [fetchedSubCat, setFetchedSubCat] = useState([]);
    const [processStep, setProcessStep] = useState(null);
    const [validStep, setValidStep] = useState(false);


    const [ex_l, setExL] = useState(true);

    const [expanded, setExpanded] = useState(new Set());
    const [product_unavailable, setProductsUnavailable] = useState([]);

    const COST_STEP_INCREMENT = 0.50;

    useEffect(() => {
    }, [product_unavailable])

    const [productsBySubCat, setProductsBySubCat] = useState({
        "1": [],
        "2": [],
        "3": [],
        "4": [],
        "5": [],
        "6": [],
        "7": [],
        "8": [],
        "9": [],
        "10": [],
        "12": [],
        "13": [],
        "14": [],
        "15": [],
        "16": [],
        "17": [],
        "18": [],
        "19": [],
        "20": [],
        "21": [],
        "22": [],
        "23": [],
        "24": [],
        "25": [],
        "26": [],
        "27": [],
        "28": [],
        "29": [],
        "30": [],
        "31": [],
        "32": [],
        "33": [],
        "34": [],
        "35": [],
    });

    const [costAdjMappings, setCostAdjMappings] = useState(new Set([]));

    const TOTAL_STEPS = 7;
    const [maxStep, setMaxStep] = useState(0);

    window.testf = (f_c) => {
        return [...costAdjMappings];
    }

    const selectOption = (e) => {
        e.preventDefault();
        let p = Array.prototype.slice.call(e.target.parentNode.attributes).find(x => x.localName == "data-select-button");
        let b = Array.prototype.slice.call(e.target.attributes).find(x => x.localName == "data-select-button-val");
        let t = document.querySelector(`[data-select-button=${p.value}]`);
        t.setAttribute("data-selected-val", b.value);
        Array.prototype.slice.call(e.target.parentNode.children).map(c => { c.classList.remove("filled-dark") });
        e.target.classList.add("filled-dark");
    }

    const getValue = (select_field_name) => {
        let t = document.querySelector(`[data-select-button=${select_field_name}]`);
        return t.getAttribute("data-selected-val");
    }

    const increment = (cat_id) => {
        setCostAdjMappings(new Set([...costAdjMappings].map(cat => {
            if (cat.entity_id == cat_id) {

                let temp = cat.value_change;
                cat.value_change = Number(temp) + COST_STEP_INCREMENT;
            }
            return cat;
        })))
    }

    const decrement = (cat_id) => {
        setCostAdjMappings(new Set([...costAdjMappings].map(cat => {
            if (cat.entity_id == cat_id) {

                let temp = cat.value_change;
                cat.value_change = Number(temp) - COST_STEP_INCREMENT;
            }
            return cat;
        })))

    }

    const selectCategory = (id) => {
        if (fmcgSubCat.findIndex(d => d == id) == -1) {
            setFmcgSubCat([...fmcgSubCat, ...[id]]);
            setTempFmcgSubCat([...tempFmcgSubCat, ...[id]]);
        } else {
            setFmcgSubCat([...fmcgSubCat].filter(d => d != id));
            setCostAdjMappings(new Set([...costAdjMappings].filter(d => d.entity_id != id)));
        }
    }

    useEffect(() => {
        // console.log(idProof);
        if (!store_icon_file) {
            props.set_toast_message("Please try again");
            return;
        }

        const file_id = generateFileId("store_icon");


        let uploadStoreIcon = async () => {

            var fd = new FormData();
            fd.append("file", store_icon_file);
            fd.append("file_name", file_id);

            await uploadFile(fd).then(data => {
                switch (data.code) {
                    case "FILE_UPLOADED":
                        setStoreIconUrl(Params.S3_BUCKET_URL + file_id + ".png");
                        props.set_toast_message("File uploaded");
                        break;
                    default:
                        props.set_toast_message("An error occurred");

                }
            })
        }

        uploadStoreIcon();

    }, [store_icon_file]);

    const getLocationCoordinates = () => {
        setFetchingLocation(true);
        navigator.geolocation.getCurrentPosition((position) => {

            props.set_toast_message("Location fetched");
            setFetchingLocation(false);
            console.log(position);
            setLocationCoords({ "latitude": position.coords.latitude, "longitude": position.coords.longitude });
        }, (err) => {
            props.set_toast_message("Error fetching location");
        })
    }

    const validateStep = (step_no) => {
        var canMoveToNext = false;
        console.log("Validating step::", step_no);
        console.log("process state::", processStep);
        console.log("p_s:", props.p_s);
        setValidStep(false);
        switch (step_no) {
            // merchant mask id
            case 1:
                if (merchant_mask_id.length > 5) {
                    canMoveToNext = true;
                    console.log("Setting process step 1");
                    setProcessStep(1);
                };
                break;
            case 2:
                if (store_address.length > 20 && locationCoords != null && store_icon_url.length > 20) {
                    if (locationCoords.latitude != undefined && locationCoords.longitude != undefined) {
                        canMoveToNext = true;
                        setProcessStep(2);
                    }

                } else {
                    props.set_toast_message("Please validate the fields");
                }
                break;
            case 3:
                if (fmcgSubCat.length > 3) {
                    canMoveToNext = true;
                    setProcessStep(3);
                } else {
                    props.set_toast_message("Please choose at least 3 categories");
                }
                break;
            case 4:
                setProcessStep(4);
                canMoveToNext = true;
                break;
            case 5:
                setProcessStep(5);
                canMoveToNext = true;
                break;
            case 6:
                setProcessStep(6);
                canMoveToNext = true;
                break;
            default:
                props.set_toast_message("Please try again");
                break;
        }
        if (canMoveToNext) {
            if (step_no < TOTAL_STEPS && (step_no)) {
                setMaxStep(maxStep + 1);
            }
        } else {
            props.set_toast_message("Please try again");
        }
    }
    const goBack = () => {

        var goTo = step - 1;
        if (goTo == 1) {
            setCostAdjMappings(new Set([]));
        }
        if (step > 1) {
            setStep(step - 1);
        }
    }

    useEffect(() => {
        if (activeMerchants.length > 0) {
            setMerchantMaskId(activeMerchants.at(0).mask_id);
        }
    }, [activeMerchants]);

    useEffect(() => {

    }, [fmcgSubCat]);

    useEffect(() => {
        let fetch_active_merchants = async () => {
            props.s_p(true);
            await getActiveMerchants().then(active_merchants => {
                if (active_merchants) {
                    setActiveMerchants(active_merchants.data);
                    props.s_p(false);
                    return;
                }
            }).catch(err => {
                if (err.response && err.response.data.statusCode == 403) {
                    props.set_toast_message("Forbidden");
                }
                props.s_p(false);
            })
        }

        let get_fmcg_sub_cats_count = async () => {
            props.s_p(true);
            await getFmcgSubCategoriesWithCount().then(fmcg_cats => {
                props.s_p(false);
                if (fmcg_cats) {

                    setFmcgCatsCount(fmcg_cats.data);

                } else {
                    props.set_toast_message("Couldn't fetch");
                }
            })
        }

        let review_details = () => {
            console.log("Merchant mask id:", merchant_mask_id);
            console.log("Store address:", store_address);
            console.log("Location coords:", locationCoords);
            console.log("Products unavailable", product_unavailable);
            console.log("FMCG sub cat:", fmcgSubCat);
            console.log("Cost Adjustment Mapping:", costAdjMappings);
        }

        if (step == 1 && (step > maxStep)) fetch_active_merchants();
        if (step == 3) get_fmcg_sub_cats_count();
        if (step == 6) review_details();
    }, [step]);

    useEffect(() => {

        let processStepOne = async (dto_body) => {
            props.s_p(true);
            console.log("STEP::", step);
            await storeStepOne({
                ...dto_body
            }).then(data => {
                props.s_p(false);
                console.log(data);
                if (data.data && data.code == "ONBOARDING_STARTED") {
                    setValidStep(true);
                    setStep(step + 1);
                    setProcessStep(-1);
                    if (data.data && data.data.store_mask_id) {
                        setStoreMaskId(data.data.store_mask_id);
                    }

                }
                else if (data.data && data.code == "ONBOARDING_RESUMED") {
                    if (data.data.activity_status) {
                        if (data.data.activity_status == "INIT_ONBOARDING" || data.data.activity_status == "IN_PROCESS") {
                            props.set_toast_message("Resuming On-boarding");
                            setValidStep(true);
                            setStep(step + 1);
                            setProcessStep(-1);
                            if (data.data && data.data.store_mask_id) {
                                setStoreMaskId(data.data.store_mask_id);
                            }
                            if (data.data && data.data.store_address) {
                                setAddress(data.data.store_address);
                            }
                            if (data.data && data.data.fmcg_sub_category_ids) {
                                setFmcgSubCat(data.data.fmcg_sub_category_ids);
                                setTempFmcgSubCat(data.data.fmcg_sub_category_ids);
                                // setTempFmcgSubCat([]);
                            }
                            if (data.data && data.data.coordinates) {
                                setLocationCoords(data.data.coordinates);
                            }
                        } else {
                            props.set_toast_message("An error occurred");
                            setProcessStep(-1);
                        }
                    }
                }
                else {
                    setValidStep(false);
                    setProcessStep(-1);
                    props.set_toast_message("An error occurred");
                }
            }).catch(err => {
                props.s_p(false);
                props.set_toast_message("An error occurred");
                console.log(err);
                setValidStep(false);
                setProcessStep(-1);
                // goBack();
            })
        }

        let processStepTwo = async (dto_body) => {
            props.s_p(true);
            await storeStepTwo({
                ...dto_body
            }).then(data => {
                props.s_p(false);
                if (data && data.message == "STORE_STEP_2_COMPLETED") {
                    setValidStep(true);
                    setProcessStep(-1);
                    setStep(step + 1);
                } else {
                    props.set_toast_message("An error occurred");
                    setProcessStep(-1);
                }

            }).catch(err => {
                props.s_p(false);
                props.set_toast_message("An error occurred");
                console.log(err);
                setValidStep(false);
                setProcessStep(-1);
                // goBack();
            })
        }
        let processStepThree = async (dto_body) => {
            props.s_p(true);
            await storeStepThree({
                ...dto_body
            }).then(data => {
                props.s_p(false);
                if (data && data.message == "STORE_STEP_3_COMPLETED") {
                    setValidStep(true);
                    setProcessStep(-1);
                    setStep(step + 1);
                }

            }).catch(err => {
                props.s_p(false);
                props.set_toast_message("An error occurred");
                console.log(err);
                setValidStep(false);
                setProcessStep(-1);
                // goBack();
            })
        }
        let processStepFour = async (dto_body) => {
            props.s_p(true);
            await storeStepFour({
                ...dto_body
            }).then(data => {
                props.s_p(false);
                if (data && data.code == "STORE_STEP_4_COMPLETED") {
                    setValidStep(true);
                    setProcessStep(-1);
                    setStep(step + 1);
                } else {
                    props.set_toast_message("An error occurred");
                    setProcessStep(-1);
                }

            }).catch(err => {
                props.s_p(false);
                props.set_toast_message("An error occurred");
                console.log(err);
                setValidStep(false);
                setProcessStep(-1);
                // goBack();
            })
        }
        let processStepFive = async (dto_body) => {
            props.s_p(true);
            await storeStepFive({
                ...dto_body
            }).then(data => {
                props.s_p(false);
                if (data && data.code == "STORE_STEP_5_COMPLETED") {
                    setValidStep(true);
                    setProcessStep(-1);
                    setStep(step + 1);
                } else {
                    props.set_toast_message("An error occurred");
                    setProcessStep(-1);
                }

            }).catch(err => {
                props.s_p(false);
                props.set_toast_message("An error occurred");
                console.log(err);
                setValidStep(false);
                setProcessStep(-1);
                // goBack();
            })
        }

        let markForReview = async (dto_body) => {
            props.s_p(true);
            await storeMarkForReview({
                ...dto_body
            }).then(data => {
                props.s_p(false);
                if (data && data.code == "STORE_ONBOARDING_IN_REVIEW") {
                    setValidStep(true);
                    setProcessStep(-1);
                    setStep(step + 1);
                    props.set_toast_message("Sent for review");
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                } else {
                    props.set_toast_message("An error occurred");
                    setProcessStep(-1);
                }

            }).catch(err => {
                props.s_p(false);
                props.set_toast_message("An error occurred");
                console.log(err);
                setValidStep(false);
                setProcessStep(-1);
                // goBack();
            })
        }

        if (processStep > 0 && !validStep) {

            switch (processStep) {
                case 1:
                    var step_1DTO = stepOneDTO;
                    step_1DTO.owner_id = merchant_mask_id;
                    processStepOne(step_1DTO);
                    break;
                case 2:
                    var step_2DTO = stepTwoDTO;
                    step_2DTO.store_mask_id = store_mask_id;
                    step_2DTO.store_address = store_address;
                    step_2DTO.coordinates.latitude = locationCoords.latitude;
                    step_2DTO.coordinates.longitude = locationCoords.longitude;
                    step_2DTO.metadata = { 'ic_url': store_icon_url }
                    processStepTwo(step_2DTO);
                    break;
                case 3:
                    var step_3DTO = stepThreeDTO;
                    step_3DTO.fmcg_sub_category_ids = fmcgSubCat;
                    step_3DTO.product_unavailable_ids = product_unavailable;
                    step_3DTO.store_mask_id = store_mask_id;
                    processStepThree(step_3DTO);
                    break;
                case 4:
                    var step_4DTO = stepFourDTO;
                    step_4DTO.entity_id_array = [];
                    step_4DTO.inventory_level_array = [];
                    fmcgSubCat.forEach(cat => {
                        step_4DTO.inventory_level_array.push(getValue("fc_" + cat));
                        step_4DTO.entity_id_array.push(cat);
                    })
                    step_4DTO.store_mask_id = store_mask_id;
                    processStepFour(step_4DTO);
                    break;
                case 5:
                    var step_5DTO = stepFiveDTO;
                    step_5DTO.store_mask_id = store_mask_id;
                    step_5DTO.fmcg_sub_category_cost_adjustment_mapping = [...costAdjMappings];
                    step_5DTO.product_cost_adjustment_mapping = [];
                    processStepFive(step_5DTO);
                    break;
                case 6:
                    var step_6DTO = { "store_mask_id": store_mask_id };
                    markForReview(step_6DTO);
                    break;
                default:
                    break;
            }

        }


    }, [processStep, validStep]);

    useEffect(() => {

        var productsFetched = {};
        let get_products_by_fmcg_sub_cat = async (cat_id) => {

            // fmcgSubCat.map(async cat_id => {
            props.s_p(true);
            await getProductsByFmcgSubCategory({ "fmcg_sub_category_id": cat_id }).then(data => {
                props.s_p(false);
                if (data) {
                    productsFetched[cat_id] = data.data;
                    setProductsBySubCat({ ...productsBySubCat, ...productsFetched });
                    setFetchedSubCat([...fetchedSubCat, ...[cat_id]]);
                }

            }
            )

            // })


        }

        if (tempFmcgSubCat) {
            console.log("tempFmcgSubCat:", tempFmcgSubCat);

            // create cost adjustment mapping

            if (tempFmcgSubCat.length > 0) {
                var arr_c_a_m = [];
                var c_a_m = costAdjustmentMapping;
                // console.log("fetchedSubCat:", fetchedSubCat);
                tempFmcgSubCat.forEach(async cat_id => {
                    // console.log("Setting cat_id=", cat_id);
                    // if (tempFmcgSubCat.findIndex(id => id == cat_id) == -1) {
                    await get_products_by_fmcg_sub_cat(cat_id);
                    c_a_m.entity_type = Params.ALIASES.fmcg_sub_cat;
                    c_a_m.entity_id = cat_id;
                    // console.log(c_a_m);
                    c_a_m.value_change = 0;
                    arr_c_a_m = [...arr_c_a_m, { ...c_a_m }];
                    setCostAdjMappings(new Set([...costAdjMappings, ...arr_c_a_m]));
                    // }/

                    console.log("arr_c_am:", arr_c_a_m);

                });
                setTempFmcgSubCat([]);


            } else {
                console.log("tempFmcgSubCat is empty");
            }
        }
    }, [tempFmcgSubCat]);

    useEffect(() => {
        console.log("CostAdjMap::", costAdjMappings);
    }, [costAdjMappings]);

    useEffect(() => {
        // setProductsBySubCat();

    }, [productsBySubCat]);

    useEffect(() => {
    }, [expanded]);
    window.onload = () => {
        console.log("window loaded");

    }
    class OrderEvent {
        constructor(name, data) {
            this.event_name = name;
            this.data = data;
        }

        getEvent() {
            return {
                "event_name": this.event_name,
                "data": this.data,
                "event": "RECEIVED"
            }
        }

    }
    function attachEventListener(e) {
        console.log("attaching event listener to iframe");
        setExL(false);
        window.addEventListener("message", (xe) => {
            console.log(xe.data);
            if (xe.origin == "http://localhost:3000") {
                console.log("cs event::", xe.data);
                setStep(step + 1);
            }
        })
    }



    return (<>
        <div className="card-container">
            <h3>Create Store</h3>
        </div><br />
        {/* 
        {ex_l && <div style={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: "100px" }}><img width={"50px"} src={spinner} /></div>}
        <iframe onLoad={(e) => { attachEventListener(e) }} style={{ width: "100%" }} src="http://localhost:3000/api/v1/data/stores/testing" ></iframe>
        */}
        {step == 1 && <form className="card-container card-form">
            <h4>Merchant</h4><br />
            <div className="field">
                <label>Select a Merchant</label>
                <p>Merchant selection</p>
                <select onChange={(e) => { setMerchantMaskId(e.target.value) }}>
                    {
                        activeMerchants && activeMerchants.map(merchant => {
                            return <option value={merchant.mask_id}>{merchant.owner_first_name + " " + merchant.owner_last_name}</option>
                        })
                    }
                </select>
            </div>

        </form>}
        {step == 2 && <form className="card-container card-form">
            <h4>Store Details</h4><br />
            <div className="field">
                <label>Store Icon</label>
                <p>Please upload an icon for the store</p>
                <input type="file" onChange={(e) => { setStoreIconFile(e.target.files[0]) }} />
            </div>
            <div className="field">
                <label>Store address</label>
                <p>Store address as per address proof</p>
                <input value={store_address} onChange={(e) => { setAddress(e.target.value) }} type="text" placeholder="Store address" />
            </div>
            <div className="field">
                <label>Store location coordinates</label>
                <p>Please allow location permission to get store location</p>
                {locationCoords && <div>
                    <span><strong>Latitude:</strong> {locationCoords.latitude} </span><br />
                    <span><strong>Longitude:</strong> {locationCoords.longitude} </span>
                </div>}
                {!fetchingLocation && <button onClick={(e) => { e.preventDefault(); getLocationCoordinates(); }} className="btn btn-dark">Get Location</button>}
                {fetchingLocation && <button disabled className="btn btn-dark">Getting location...</button>}
                {locationCoords && <span style={{ fontWeight: "bold", color: "green" }}>Location coordinates saved</span>}
            </div>
        </form>}
        {step == 3 && <form className="card-container card-form">
            <h4>FMCG Category selection</h4><br />
            <div className="field">
                <label>Select all the categories which are applicable to this store</label>
                <p>All products are classified into FMCG categories & sub-categories</p><br />
                {fmcgCatsCount && fmcgCatsCount.map(f_c => {
                    return <div style={{ width: "100%" }}>
                        <div className="sub-field checkbox" style={{ display: "flex", flexDirection: "flex-start" }}>
                            {!expanded.has(f_c.fmcg_sub_category_id) && <i className="expand-icon" onClick={() => { setExpanded(new Set([...expanded, ...[f_c.fmcg_sub_category_id]])) }} style={{ fontSize: "1.1em" }}>+</i>}
                            {expanded.has(f_c.fmcg_sub_category_id) && <i className="expand-icon" onClick={() => { setExpanded(new Set([...expanded].filter(i => i != f_c.fmcg_sub_category_id))) }} style={{ fontSize: "1.1em" }}>-</i>}

                            <label for={"category_sub-" + f_c.fmcg_sub_category_id}>
                                <span style={{ fontWeight: "bold", width: "95%" }}>{FMCG_SUB_CATEGORIES.filter(fc => fc.id == f_c.fmcg_sub_category_id).at(0).name} </span> | {f_c.count} products</label>

                            <input checked={fmcgSubCat.findIndex(d => d == f_c.fmcg_sub_category_id) != -1} onClick={() => { selectCategory(f_c.fmcg_sub_category_id); }} type="checkbox" id={"category_sub-" + f_c.fmcg_sub_category_id} />

                        </div>
                        {expanded.has(f_c.fmcg_sub_category_id) && <div className="sub-items">
                            {/* product items under fmcg sub category */}
                            <p> {FMCG_SUB_CATEGORIES.filter(fc => fc.id == f_c.fmcg_sub_category_id).at(0).name} </p>
                            <ul>
                                {productsBySubCat[f_c.fmcg_sub_category_id] && productsBySubCat[f_c.fmcg_sub_category_id].map(
                                    (product, ind) => {

                                        return <li>
                                            <label onClick={() => { setProductsUnavailable([...product_unavailable, ...[product._id]]) }} for={"product_fmcg_" + f_c.fmcg_sub_category_id + ind}>{ind + 1}. {product.brand_name} {product.name} {product.variant_name && "| " + product.variant_name} <span className="mrp" style={{ fontSize: "0.8em", padding: "0.25em" }}>&#8377; {product.max_retail_price}</span></label>
                                            {fmcgSubCat.findIndex(d => d == f_c.fmcg_sub_category_id) != -1 && <input id={"product_fmcg_" + f_c.fmcg_sub_category_id + ind} type="checkbox" />}
                                        </li>
                                    }
                                )}
                            </ul>
                        </div>}
                    </div>


                })
                }



            </div>
        </form>}

        {step == 4 && <form className="card-container card-form">
            <h4>Inventory Level Selection</h4><br />
            <div className="field">
                <label>Select appropriate inventory levels</label>
                <p>Choose the best suitable inventory level for each FMCG Sub-category</p>
                {fmcgCatsCount && fmcgCatsCount.filter(c => fmcgSubCat.findIndex(d => c.fmcg_sub_category_id == d) != -1).map(f_c => {
                    return <div style={{ width: "100%" }}>
                        <div className="sub-field checkbox" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>

                            <label for={"category_sub-" + f_c.fmcg_sub_category_id}>
                                <span style={{ fontWeight: "bold", width: "95%" }}>{FMCG_SUB_CATEGORIES.filter(fc => fc.id == f_c.fmcg_sub_category_id).at(0).name} </span> | {f_c.count} products</label>

                            <div data-selected-val="level_1" data-select-button={"fc_" + f_c.fmcg_sub_category_id} className="select_button_field">

                                <button data-select-button-val={"level_1"} onClick={(e) => { selectOption(e) }} className="btn btn-dark select-button filled-dark md-font-2">{"1 (<50 units)"}</button>
                                <button data-select-button-val={"level_2"} onClick={(e) => { selectOption(e) }} className="btn btn-dark select-button md-font-2">{"2 (80-150 units)"}</button>
                                <button data-select-button-val={"level_3"} onClick={(e) => { selectOption(e) }} className="btn btn-dark select-button md-font-2">{"3 (180-220 units)"}</button>
                                <button data-select-button-val={"level_4"} onClick={(e) => { selectOption(e) }} className="btn btn-dark select-button md-font-2">{"4 (>250 units)"}</button>


                            </div>

                        </div>

                    </div>


                })
                }
            </div>
        </form>}

        {step == 5 && <form className="card-form card-container">
            <h4>Cost Adjustment for FMCG Categories</h4><br />
            <div className="field">
                <label></label>
                <p></p>
                {fmcgCatsCount && fmcgCatsCount.filter(c => fmcgSubCat.findIndex(d => c.fmcg_sub_category_id == d) != -1).map(f_c => {
                    return <div style={{ width: "100%" }}>
                        <div className="sub-field checkbox" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>

                            <label for={"category_sub-" + f_c.fmcg_sub_category_id}>
                                <span style={{ fontWeight: "bold", width: "95%" }}>{FMCG_SUB_CATEGORIES.filter(fc => fc.id == f_c.fmcg_sub_category_id).at(0).name} </span> | {f_c.count} products</label>
                            <div>
                                <span>
                                    <i onClick={() => { increment(f_c.fmcg_sub_category_id) }} className="fas fa-plus"></i>
                                    &#8377;{costAdjMappings.size > 0 && <input id="quantity" type="text" value={[...costAdjMappings].filter(i => i.entity_id == f_c.fmcg_sub_category_id).at(0).value_change} onChange={(e) => {
                                        if (e.target.value > 0) {
                                            setCostAdjMappings(new Set([...costAdjMappings].map(cat => {
                                                if (cat.entity_id == f_c.fmcg_sub_category_id) {
                                                    cat.value_change = e.target.value;
                                                }
                                                return cat;
                                            })))
                                        }
                                    }} />

                                    }
                                    <i onClick={() => { decrement(f_c.fmcg_sub_category_id) }} className="fas fa-minus"></i>
                                </span>
                            </div>
                        </div>

                    </div>


                })
                }
            </div>
        </form>}

        {step == 6 && <>
            <div className="card-form card-container">
                <h4>Review</h4>

            </div>
            <br />
            <div className="card-container">
                <div className="field">
                    <label style={{ fontWeight: "bold" }}>Merchant Mask ID</label>
                    <p>{merchant_mask_id}</p>
                </div>
            </div>
            <div className="card-container">
                <div className="field">
                    <label style={{ fontWeight: "bold" }}>Store Owner name</label>
                    <p>{activeMerchants.find(merchant => merchant.mask_id == merchant_mask_id) && (activeMerchants.find(merchant => merchant.mask_id == merchant_mask_id)).owner_first_name + " " + (activeMerchants.find(merchant => merchant.mask_id == merchant_mask_id)).owner_last_name}</p>
                </div>
            </div>
            <div className="card-container">
                <div className="field">
                    <label style={{ fontWeight: "bold" }}>Store Address</label>
                    <p>{store_address}</p>
                </div>
            </div>
            <div className="card-container">
                <div className="field">
                    <label style={{ fontWeight: "bold" }}>FMCG Sub-categories</label>
                    {fmcgSubCat.map(cat_id => {
                        return <p>
                            {FMCG_SUB_CATEGORIES.find(i => i.id == cat_id).name}
                        </p>
                    })}
                </div>
            </div>
            <div className="card-container">
                <div className="field">
                    <label style={{ fontWeight: "bold" }}>Cost Adjustment by FMCG Sub-category</label>
                    <br /><br />
                    <table>
                        <tr>
                            <th>Sub-category</th>
                            <th>Cost adjustment</th>
                        </tr>
                        {fmcgSubCat.map((cat_id, ind) => {
                            return <tr>
                                <td>{ind + 1}. {FMCG_SUB_CATEGORIES.find(i => i.id == cat_id).name}</td>
                                <td style={{ textAlign: "center" }}>{[...costAdjMappings].find(cam => cam.entity_id == cat_id) && [...costAdjMappings].find(cam => cam.entity_id == cat_id).value_change} </td>
                            </tr>
                        })}
                    </table>

                </div>
            </div>
        </>
        }

        <div className="btn-box">
            {(step < TOTAL_STEPS) && (step > 0) && !(step == TOTAL_STEPS - 1) && !props.p_s && <button className="btn btn-dark" onClick={(e) => { e.preventDefault(); validateStep(step); }}>Next</button>}
            {(step < TOTAL_STEPS) && (step > 0) && (step == TOTAL_STEPS - 1) && !props.p_s && <button className="btn btn-dark" onClick={(e) => { e.preventDefault(); validateStep(step); }}>Submit</button>}
            {(step < TOTAL_STEPS) && (step > 1) && props.p_s && !validStep && <button className="btn btn-dark" >Saving...</button>}
            {step > 1 && <button onClick={() => { goBack(); }} className="btn btn-nav">Go back</button>}
        </div>

    </>)
}

export default CreateStore;