import axios from "axios";
import Params from "../constants";


export const BRAND_TEMPLATE = {
    "name": null,
    "parent_company": null,
    "iot_20": false,
    "fmcg_category_id": -1,
    "fmcg_sub_category_id": -1,
    "average_price_bracket": null,
    "metadata": null,
    "mask_id": null
}

const auth_headers = Params.AXIOS_AUTH_HEADERS(localStorage.getItem("x-t"));

export const fetchBrands = async () => {

    return axios.post(Params.OPS_SERVICE_BASE_URL + Params.FETCH_PRESET_BRANDS, {}, auth_headers).then(
        (res) => res.data
    ).catch(err => console.log(err));
}

