import { useEffect, useState } from "react";
import { ACTIVITY_STATUS, MERCHANT_TEMPLATE, fetchDraftMerchants } from "../../merchantTemplate";
import { convertISOStringToMonthDay } from "../../../utils";
import { Link, useParams } from "react-router-dom";



function ListDraftMerchants(props) {

    const [merchants, setMerchants] = useState([]);

    useEffect(() => {
        props.s_p(true);
        let fetch_draft_merchants = async () => {
            await fetchDraftMerchants().then(draft_merchants => {
                if (draft_merchants) {
                    setMerchants(draft_merchants.data);
                    props.s_p(false);
                    return;
                }
                setTimeout(() => {
                    !draft_merchants && fetch_draft_merchants();
                }, 3000);
            }).catch(err => {
                if(err.response && err.response.data.statusCode == 403){
                    props.set_toast_message("Forbidden");
                }
                props.s_p(false);
            })


        }
        fetch_draft_merchants();
    }, []);
    return (
        <>
            <div className="card-container left-aligned margin15 rounded">
                <h3 style={{ fontSize: "1.2em" }}>Draft Merchants</h3>
                <p className="md-font">List of merchants recently added and not yet approved</p>
            </div>
            <div className="overflow-container">
                {merchants && merchants.map(merchant_data => {
                    var merchant = MERCHANT_TEMPLATE;
                    merchant = { ...merchant_data };
                    return <div className="card-container catalogue-tile row">
                        {/* <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzRKni2nZ5o_isZotHkFMrIGFfY3X8kqBQ4A&usqp=CAU"} /> */}

                        <Link to={`/merchant/in-review/edit/${merchant.mask_id}`} className="no-decor-link" >
                            <div className="info">
                                <h4 style={{ textWrap: "balance" }}>{merchant.owner_first_name + "" + merchant.owner_last_name}</h4>
                                <span style={{ fontSize: "0.8rem" }}>{merchant.store_name}</span>
                                {/* {convertISOStringToMonthDay(product.createdAt)} */}
                                <span style={{ color: "darkgray", fontSize: "0.8rem" }}>Added on {merchant.createdAt && convertISOStringToMonthDay(merchant.createdAt)} </span>
                            </div>
                        </Link>
                        <div></div>
                        {/* <div></div> */}
                        {/* <div></div> */}
                        {/* <div></div> */}
                        <div>
                            <span style={{ color: "#bc3344", fontWeight: "bolder" }}>{ACTIVITY_STATUS[merchant.activity_status]}</span>
                        </div>
                        <div></div>
                    </div>
                })
                }
            </div>
        </>
    )
}

export default ListDraftMerchants;