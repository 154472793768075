

/**
 * ENVIRONMENT--- 0 (PRODUCTION)
 * ENVIRONMENT--- 1 (DEVELOPMENT)
 * 
 * 
 */
const ENVIRONMENT = [
    {
        "name": "PRODUCTION",
        "host": "https://appdata.kiranadigital.com"
        // "host": "http://34.207.106.13:3000"
        // "host":"http://52.70.213.53:3000"
    },
    {
        "name": "LOCAL_SERVER",
        "host": "https://172.16.10.71:3000"
    },
    {
        "name": "DEVELOPMENT",
        "host": "https://localhost:3000"
    }
]


const baseUrl = `${process.env.NODE_ENV == "development" ? ENVIRONMENT[2].host : ENVIRONMENT[0].host}`
const Params = {
    TOKEN_ALIAS: "ac-t+x",
    // OPS_SERVICE_BASE_URL: "http://192.168.1.57:3000/api/v1", nbc
    // OPS_SERVICE_BASE_URL: "http://192.168.1.107:3000/api/v1",
    OPS_SERVICE_BASE_URL: `${baseUrl}/api/v1`,

    // product
    FETCH_PRESET_VARIANTS: "/data/products/preset/get-variants",
    FETCH_PRESET_BRANDS: "/data/products/preset/get-brands",
    ADD_BRAND: "/data/products/preset/add-brand",
    ADD_VARIANT: "/data/products/preset/add-variant",
    ADD_PRODUCT: "/data/products/add",
    FETCH_PRODUCTS: "/data/products/fetch",
    UPDATE_PRODUCT: "/data/products/update",
    SEARCH_PRODUCTS: "/data/products/search",
    GET_FMCG_SUB_CAT_COUNT: "/data/products/internal/get-fmcg-sub-category-count",
    GET_PRODUCTS_BY_FMCG_SUB_CAT: "/data/products/by-fmcg-sub-category",
    ALIASES: {
        fmcg_sub_cat: "FMCG_SUB_CATEGORY",
        product: "PRODUCT"
    },
    // merchant
    ADD_MERCHANT: "/data/merchants/add/",
    FETCH_DRAFT_MERCHANTS: "/data/merchants/get/drafts",
    FETCH_ACTIVE_MERCHANTS: "/data/merchants/get/active",
    GET_MERCHANT: "/data/merchants/get",
    MARK_MERCHANT_ACTIVE:"/data/merchants/mark/active",
    MARK_MERCHANT_INACTIVE:"/data/merchants/mark/inactive",
    // store
    ONBOARDING_STEP_ONE: "/data/stores/on-board",
    ONBOARDING_STEP_TWO: "/data/stores/onboarding/step2",
    ONBOARDING_STEP_THREE: "/data/stores/onboarding/step3",
    ONBOARDING_STEP_FOUR: "/data/stores/onboarding/step4",
    ONBOARDING_STEP_FIVE: "/data/stores/onboarding/step5",
    ONBOARDING_MARK_REVIEW:"/data/stores/onboarding/complete",
    // auth
    LOGIN_INITIATE: "/auth/initiate",
    AXIOS_AUTH_HEADERS: (token) => {
        return {
            "headers": {
                "z_string": token
            }
        }
    },
    // utils
    S3_BUCKET_URL: "https://kdops.s3.amazonaws.com/",
    UTILS_UPLOAD_FILE: "/internal/utils/file-upload",
    UTILS_TEXT_FROM_IMAGE: "/internal/utils/read-image"

}



export const OPERATION_PARAMETERS = {
    "minimum_store_age": 2,
    "minimum_area_sq_feet": 10,
    "minimum_area_gaj": 2
}



export default Params;